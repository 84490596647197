import React, { useState } from 'react'
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import apiRequest from '../api/api';
import handleApiResponse from '../utils/handleApiResponse ';

function useResendEmailVerification() {
    const navigate = useNavigate()
    const resendVerificationEmail = async (email) => {
        const success = handleInputErrors({ email })

        if (!success) return;
        try {
            const res = await apiRequest("POST", `/user/resendVerificationEmail`, JSON.stringify({ email: email }))
            // console.log(res)
      
            handleApiResponse(res)
            navigate("/user/auth/verify-email")

        }

        catch (err) {
            console.log(err)
        }

    }

    return { resendVerificationEmail };
}

export default useResendEmailVerification


function handleInputErrors({ email }) {
    if (!email) {
        toast.error("Please provide your email address", {
            duration: 7000,style:{marginTop:"70px"}})
        return false
    }

    return true
}