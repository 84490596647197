import React from 'react'

function SelectAllItems({ filteredData, toggleSelectAll,selectedItems }) {
    return (
        <div>

            {filteredData && filteredData.length >= 2 && (
                <div className="form-group flex items-center mx-6 mt-2 pb-1 border-b-2 border-[#ababab] justify-between">
                    <input
                        onChange={toggleSelectAll}
                        checked={
                            selectedItems.length === filteredData.length && filteredData.length > 0
                        }
                        type="checkbox"
                        id="select-all"
                    />
                    <label htmlFor="select-all" className="font-semibold">
                        Select All
                    </label>
                </div>
            )}
        </div>
    )
}

export default SelectAllItems