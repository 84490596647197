import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppData } from '../context/AppContext';
import toast, { Toaster } from "react-hot-toast"; // Import Toaster
import { LocalStorageManager } from '../utils/local-storage-manager';
import ProcessingLogin from './ProcessingLogin';
function GoogleAuthCallback() {
    const { setAuthUser } = useAppData();
    const navigate = useNavigate();
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const apiRes = params.get('response');
        let apiResErr = params.get('error');
        if (apiRes) {
            // Parse and handle the user data
            try {
                let parseApiRes = JSON.parse(apiRes)
                LocalStorageManager.loggedInUser.info = JSON.stringify(parseApiRes.data)
                LocalStorageManager.token.userToken = parseApiRes.access_token
                setAuthUser(parseApiRes.data);
                // toast.success("Sign in successful! Redirecting...", {
                //     duration: 7000,
                // });
                // Delay navigation to allow the toast to be shown
                setTimeout(() => {
                    navigate('/'); // Navigate to the main page or your desired route
                }, 2000);
            } catch (error) {
                console.error('Error parsing user data:', error);
                toast.error('Failed to parse user data', {
                    duration: 7000,  style:{marginTop:"70px"}}); // Show error if parsing fails
            }
        } else if (apiResErr) {
            const parseError = JSON.parse(apiResErr);
            apiResErr = null
            toast.error(parseError.message || 'An error occurred during authentication', {
                duration: 7000, style:{marginTop:"70px"}}); // Show error message
            setTimeout(() => {
                navigate('/signin'); // Navigate to the login page
            }, 2000); // Delay for 2 seconds
        } else {
            navigate('/signin'); // If no response or error, navigate to login
        }
    }, []); // Include dependencies // navigate, setAuthUser
    return (
        <>
            <Toaster />
            <ProcessingLogin />
        </>
    );
}
export default GoogleAuthCallback;







