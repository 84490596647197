import React from 'react';

const Modal = ({ title, content, onClose, onConfirm, confirmText = 'Confirm', cancelText = 'Cancel', isVisible , buttonColor = '#ef4444' ,data,selectedFileTags}) => {
  if (!isVisible) return null; // Modal is hidden when not visible


  const isDisabled=data &&  data.length >0 || selectedFileTags &&  selectedFileTags.length>0
  console.log(selectedFileTags)
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40">
      <div
        style={{
          background: 'white',
          position: 'relative',
          width: '100%',
          maxWidth: '500px',
          padding: '20px',
          borderRadius: '8px',
        }}
      >
        <div onClick={onClose} className="absolute right-4 cursor-pointer hover:opacity-80">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="black"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-x h-4 w-4"
          >
            <path d="M18 6 6 18"></path>
            <path d="m6 6 12 12"></path>
          </svg>
        </div>
        <h2 className="font-bold text-black">{title}</h2>
        <div className="text-black mt-3">{content}</div>
        <div className="flex justify-end mt-8">
         <button
          style={{ backgroundColor: buttonColor ,opacity:!isDisabled? 0.5 :1}}
            className="p-2 rounded-lg  font-semibold text-white hover:opacity-80"
            onClick={onConfirm}
            type='button'
            disabled={!isDisabled}
          >
            {confirmText}
          </button>

 
        </div>
      </div>
    </div>
  );
};

export default Modal;
