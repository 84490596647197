import React, { useEffect, useState, useRef } from "react";
import logo1 from "../images/logo3.png";

import { useAppData } from "../context/AppContext";
import FileUploadPopup from "./FileUploadPopup";
import { sidebar } from "react-icons-kit/feather/sidebar";
import Icon from "react-icons-kit";
import TooltipContainer from "./Common/TooltipContainer";
import RoundedButton from "./Common/RoundedButton";
import apiRequest from "../api/api";
import FilesUploadedData from "./FilesUploadedData";
import toast from "react-hot-toast";
import handleApiResponse from "../utils/handleApiResponse ";
import Modal from "./Modal";
import SideBarContent from "./SideBarContent";
import { tags } from 'react-icons-kit/fa/tags'
import { ic_insert_drive_file } from 'react-icons-kit/md/ic_insert_drive_file'
import { ic_menu_book } from 'react-icons-kit/md/ic_menu_book'
import Files from "./Files";
import TagsList from "./TagsList";
import { getImageByExtension } from "./FileIcon";


const SIDEBAR_AUTO_CLOSE_MAX_SCREEN_WIDTH = 1000;

function SideBar({
  setLoading,
  loading,
  docType,
  setDocType
}) {
  const { authUser, setUserSelectedDocs, userSelectedDocs } = useAppData();
  const [files, setFiles] = useState([]);
  const [fetchedFileTags, setFetchedFileTags] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State variable for showing delete modal
  const [fileToDelete, setFileToDelete] = useState(null); // Store conversation to delete
  const [searchTerm, setSearchTerm] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [fileTags, setFileTags] = useState([]);
  const sidebarRef = useRef();
  const toggleButtonRef = useRef();


  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };



  const togglePopup = () => {
    setIsPopupOpen((prev) => !prev);
  };

  const handleConversationSelection = (fileId, totalPages, fileName) => {

    // Toggle the selection status of the conversation
    setUserSelectedDocs((prevSelection) => {
      const updatedSelection = prevSelection.find(doc => doc.fileId === fileId)
        ? prevSelection.filter(doc => doc.fileId !== fileId) // Remove if already selected
        : [...prevSelection, { fileId, pageRanges: totalPages, fileName }]; // Add new entry

      // Update localStorage with the new selection
      localStorage.setItem("selectedFiles", JSON.stringify(updatedSelection));

      return updatedSelection;
    });

    setLoading(false);
  };

  const handleConversationSelectionDelete = (fileId) => {
    // Store conversation to delete
    setFileToDelete(fileId);
    // Toggle delete modal visibility
    setShowDeleteModal(true);
  };


  const handleDeleteConfirmation = async () => {

    try {
      const responseData = await apiRequest(
        "DELETE", `/files?fileId=${fileToDelete}`
      );

      const res = handleApiResponse(responseData)

      // Update local state immediately after successful API response
      if (res.statusCode = 200) {
        setFiles((prevFiles) => {
          const updatedFiles = prevFiles.filter((file) => file.id !== fileToDelete);

          // Update localStorage with the updated list of selected conversations
          const selectedFiles = JSON.parse(localStorage.getItem("selectedFiles")) || [];

          const updatedSelectedFiles = selectedFiles.filter((file) => file.fileId !== fileToDelete);
          localStorage.setItem("selectedFiles", JSON.stringify(updatedSelectedFiles));

          setUserSelectedDocs(updatedSelectedFiles);

          return updatedFiles;
        });
      }

      setShowDeleteModal(false);
    } catch (err) {

      console.error("Error deleting file:", err.message);
    }
  };

  //getting users files
  const fetchFiles = async () => {
    try {
      // Fetch user's files
      const responseData = await apiRequest(
        "GET",
        `/files`,
        {}
      );
      const res = handleApiResponse(responseData)
      if (res.statusCode == 200) {
        setFiles(res.data);
      }
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };
  useEffect(() => {
    fetchFiles();
  }, []);


  const getFilesTags = async () => {
    const responseData = await apiRequest("GET", "/files/fileTags")

    const res = handleApiResponse(responseData)
    if (res.statusCode == 200) {
      setFetchedFileTags(res.data)
    }

  }

  useEffect(() => {
    getFilesTags()

  }, [])




  // To initialize the state from localStorage when the component mounts
  useEffect(() => {
    const savedSelection = localStorage.getItem("selectedFiles");
    if (savedSelection) {
      setUserSelectedDocs(JSON.parse(savedSelection));
    }
  }, []);

  const toggleSelectAll = () => {
    // Get the current list from localStorage
    const storedSelection = JSON.parse(localStorage.getItem("selectedFiles")) || [];

    // If all items are currently selected, deselect them
    if (storedSelection.length === files?.length) {
      setUserSelectedDocs([]);

      localStorage.setItem("selectedFiles", JSON.stringify([]));
    } else {
      // Otherwise, select all items
      const selectedDocs = files?.map((file) => ({
        fileId: file.id,
        pageRanges: file.pageCount,
        fileName: file.originalName,

        // or however you are getting page count
      }));
      setUserSelectedDocs(selectedDocs);


      localStorage.setItem(
        "selectedFiles",
        JSON.stringify(selectedDocs)
      );
    }


  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= SIDEBAR_AUTO_CLOSE_MAX_SCREEN_WIDTH) {
        setIsCollapsed(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        window.innerWidth <= SIDEBAR_AUTO_CLOSE_MAX_SCREEN_WIDTH &&
        (!toggleButtonRef.current ||
          !toggleButtonRef.current.contains(event.target))
      )
        setIsCollapsed(true);
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isCollapsed]);

  useEffect(() => {
      setIsCollapsed(!isCollapsed);
  }, [])

  const tabs = [
    {
      id: 1, label: 'Files', icon: ic_insert_drive_file, content: <Files
        files={files}
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
        togglePopup={togglePopup}
        loading={false}
        toggleSelectAll={toggleSelectAll}
        userSelectedDocs={userSelectedDocs}
        handleConversationSelection={handleConversationSelection}
        handleConversationSelectionDelete={handleConversationSelectionDelete}
        getImageByExtension={getImageByExtension}
        fileTags={fileTags}
        setFileTags={setFileTags}
        fetchedFileTags={fetchedFileTags}
        getFiles={fetchFiles}

      />
    },
    {
      id: 2, label: 'Tags', icon: tags,
      content: <TagsList
        fetchedFileTags={fetchedFileTags}
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}
        loading={false}

      />
    },
    // {
    //   id: 3, label: 'Knowledge Base', icon:ic_menu_book, content: "This is the Knowledge Base tab content."
    // },
  ];

  return (
    <div style={{ position: "relative" }}>
      <aside
        ref={sidebarRef}
        className={`sidebar-wrapper text-white top-0 left-0 z-40 h-safe-screen relative sidebar bg-[#ebebeb] ${isCollapsed ? "w-0" : "w-80"}    transition-all duration-300`}
        aria-label="Sidebar"
        style={{ borderRight: "1px solid #cdcdcd" }}
      >
        <div className="h-full overflow-y-auto bg-gray-50 dark:bg-gray-800 flex flex-col">
          <div className="h-14 mx-5 flex gap-2 items-center justify-start">
            {isCollapsed ? (
              <>
                <TooltipContainer
                  style={{ position: "absolute", top: "10px" }}
                  content={"Open sidebar"}
                  id={"open-sidebar-tooltip"}
                >
                  <RoundedButton
                    onClick={toggleSidebar}
                    ref={toggleButtonRef}
                    icon={
                      <Icon
                        icon={sidebar}
                        size={24}
                        className="text-[#5d5d5d]"
                      />
                    }
                  ></RoundedButton>
                </TooltipContainer>
              </>
            ) : (
              <TooltipContainer
                content={"Close sidebar"}
                id={"close-sidebar-tooltip"}
              >
                <RoundedButton
                  onClick={toggleSidebar}
                  icon={
                    <Icon icon={sidebar} size={24} className="text-[#5d5d5d]" />
                  }
                ></RoundedButton>
              </TooltipContainer>
            )}
            <a
              href="/"
              className="flex items-center space-x-3 rtl:space-x-reverse mt-2 w-max bg-[#d6d6d6] rounded-lg z-1"
            >
              <img style={{padding:"6px"}} className="navbvar-logo " src={logo1} alt=" Logo" />
            </a>
          </div>

          {isPopupOpen && (
            <FileUploadPopup
              onClose={togglePopup}>
              <FilesUploadedData
                popupMode={true}
                closePopup={togglePopup}
                onFileUpload={(filename, fileType) => {
                  // add newly uploaded file to the list
                  setFiles([
                    ...files,
                    {
                      name: filename,
                      type: fileType,
                    },


                  ]);
                  fetchFiles();

                }}
              />
            </FileUploadPopup>
          )}
          <div className="mx-1 ">
            <SideBarContent files={files} getFilesTags={getFilesTags} fetchFiles={fetchFiles}  docType={docType} setDocType={setDocType} tabs={tabs} />
          </div>

        </div>
      </aside>

      {/* Delete confirmation modal */}
      {showDeleteModal && (
        <Modal
          title="Deleting File"
          content="Are you sure you want to delete this file?"
          isVisible={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteConfirmation}
          confirmText="Delete"
          cancelText="Cancel"
          data={files}
        />

      )}

    </div>
  );
}

export default SideBar;
