import { createContext, useContext, useState } from "react";
import { LocalStorageManager } from "../utils/local-storage-manager";

export const AppDataContext = createContext();

export const useAppData = () => {
    return useContext(AppDataContext)
}

export const AppDataContextProvider = ({ children }) => {
    const [authUser, setAuthUser] = useState(LocalStorageManager.loggedInUser.info || null)
    const [userSelectedDocs, setUserSelectedDocs] = useState([])
    const [userSelectedTags, setUserSelectedTags] = useState([]);

    const value = { authUser, setAuthUser, userSelectedDocs, setUserSelectedDocs, userSelectedTags, setUserSelectedTags };

    return <AppDataContext.Provider value={value}>{children}</AppDataContext.Provider>
}