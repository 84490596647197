import React, { useState } from 'react'
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useAppData } from '../context/AppContext';
import { apiEndPoint, publicIP } from '../components/configuratiion';
import apiRequest from '../api/api';
import handleApiResponse from '../utils/handleApiResponse ';

function useForgotPass() {
    const { setAuthUser } = useAppData()

    const navigate = useNavigate()


    const forgotPassword = async (email) => {
        const success = handleInputErrors({ email })

        if (!success) return;
        try {
            const res = await apiRequest("POST",`/auth/forgotPassword`, JSON.stringify({ email }))
             handleApiResponse(res)
             navigate("/reset-password")
        }

        catch (err) {
            // toast.error(err.message)
        }

      

    }

    return {  forgotPassword };
}

export default useForgotPass


function handleInputErrors({ email }) {
    if (!email ) {
        toast.error("Please provide your email address",{ duration: 7000,  style:{marginTop:"70px"}})
        return false
    }

    return true
}