import React, { useState } from 'react'
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useAppData } from '../context/AppContext';
import { apiEndPoint, publicIP } from '../components/configuratiion';
import apiRequest from '../api/api';
import handleApiResponse from '../utils/handleApiResponse ';
import { LocalStorageManager } from '../utils/local-storage-manager';

export const Roles = {
    USER: "User",
    ADMIN: "Admin",
    // Add other roles as needed
};

function useLogin() {
    const { setAuthUser } = useAppData()

    const navigate = useNavigate()

    const login = async (email, password) => {
        const success = handleInputErrors({ email, password })

        if (!success) return;
        try {
            const res = await apiRequest("POST", `/auth/signIn`, JSON.stringify({ email, password }))
            const apiRes = handleApiResponse(res)

            LocalStorageManager.loggedInUser.info = JSON.stringify(apiRes.data)
            LocalStorageManager.token.userToken = apiRes.access_token
            setAuthUser(apiRes.data)
            if (apiRes.data.roles.find((role) => role === Roles.USER)) {
                navigate("/");
            } else if (apiRes.data.roles.find((role) => role === Roles.ADMIN)) {
                navigate("/admin");
            }

        }

        catch (err) {
            // console.log(err.response.data?.message,"err")
            // const loginErr=err.response.data?.message
            // toast.error(loginErr)
        }



    }

    return { login };
}

export default useLogin


function handleInputErrors({ email, password }) {
    if (!email || !password) {
        toast.error("Please fill in all required fields", {
            duration: 7000, style:{marginTop:"70px"}})
        return false
    }
    return true
}