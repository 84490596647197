import axios from "axios";
import { getServerEndpoint } from "../getServerEndPoint";
import { LocalStorageManager } from "../utils/local-storage-manager";

const serverEndpoint = getServerEndpoint();
const axiosInstance = axios.create({
  baseURL: serverEndpoint, //base URL
});
// Request interceptor to attach token to headers
axiosInstance.interceptors.request.use(
  (config) => {
    const token = LocalStorageManager.token.userToken;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error, "error");
    // Handle specific errors here, like token expiration
    if (
      error.response &&
      (error.response.data && error.response.data.statusCode === 401 )
    ) {
      LocalStorageManager.cleanLocalStorage()
      window.location.href = "/signin";

    }

    if (error.response &&
      (error.response.data && error.response.data.statusCode === 400)) {
     return error.response.data
    }

    if (error.response &&
      (error.response.data && (error.response.data.statusCode === 404 || error.response.data.statusCode === 406))) {
     return error.response.data
    }


    if (error.response &&
      (error.response.data && error.response.data.statusCode === 403)) {
      //admin check

      window.location.href = "/";
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
