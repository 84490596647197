// src/utils/fileIcons.js

// Import all icons you need
import pdfIcon from "../images/pdfIcon.png";
import txtIcon from "../images/txtIcon.png";
import pptIcon from "../images/pptIcon.png";
import epubIcon from "../images/epubIcon.png";
import rftIcon from "../images/rftIcon.png";
import msWordIcon from "../images/msWordIcon.png";
import xlsx from "../images/xlsx.png";
import html from "../images/html.png";
import md from "../images/mdFile.png";
import weburlIcon from "../images/weburlIcon.png";
// Define and export the function
export const getImageByExtension = (filetype) => {
  switch (filetype.toLowerCase()) {
    case "pdf":
      return pdfIcon;

    case "doc":
      return msWordIcon;

    case "docx":
      return msWordIcon;

    case "txt":
      return txtIcon;

    case "epub":
      return epubIcon;

    case "html":
      return html

    case "md":
      return md

    case "ppt":
      return pptIcon;

    case "pptx":
      return pptIcon;

    case "rft":
      return rftIcon;

    case "xlsx":
      return xlsx;

    default:
      return weburlIcon;
  }
};
