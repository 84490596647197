import React, { useState } from 'react';
import { tags } from 'react-icons-kit/fa/tags';
import Icon from "react-icons-kit";
import TooltipContainer from './Common/TooltipContainer';
import Modal from './Modal';
import apiRequest from '../api/api';
import toast from 'react-hot-toast';
import handleApiResponse from '../utils/handleApiResponse ';

function TagEditor({ selectedFileId, fileTags, setFileTags, files, getFiles }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [tag, setTag] = useState("");
  const [removedTags, setRemovedTags] = useState([]); // Track removed tags for existing file tags

  // Find the selected file and its existing tags
  const selectedFile = files.find(file => file.id === selectedFileId);
  const selectedFileTags = selectedFile ? selectedFile.tags : [];

  const removeTag = (tagToRemove) => {
    // Check if tag exists in session tags or existing file tags
    if (fileTags.includes(tagToRemove)) {
      setFileTags(fileTags.filter(tag => tag !== tagToRemove));
    } else if (selectedFileTags.includes(tagToRemove)) {

      setRemovedTags([...removedTags, tagToRemove]); // Track the removed tag
    }
  };

  const addTag = () => {
    const validTagPattern = /^[a-zA-Z0-9-_]+$/;

    if (!tag || tag === "") {
      toast.error("Required", { duration: 7000 });
      return;
    }

    if (tag.trim() && validTagPattern.test(tag)) {
      if (!fileTags.includes(tag) && !selectedFileTags.includes(tag)) {
        setFileTags([...fileTags, tag]);
        setTag("");
      } else {
        toast.error("This tag already exists.", { duration: 7000 });
      }
    } else {
      toast.error("Invalid characters. Only letters, numbers, hyphens, and underscores are allowed.", { duration: 7000 });
    }
  };

  const updateFileTags = async () => {
    // Combine tags, excluding removed ones
    const combinedTags = Array.from(
      new Set([...selectedFileTags, ...fileTags].filter(tag => !removedTags.includes(tag)))
    );
    const dataToSend = { fileTags: combinedTags };

    const responseData = await apiRequest("PUT", `/files/fileTags?fileId=${selectedFileId}`, dataToSend);
    const res = handleApiResponse(responseData);

    if (res.statusCode === 200) {
      getFiles();
      setFileTags([]); // Clear session tags after saving
      setRemovedTags([]); // Clear removed tags after saving
      setIsPopupOpen(false);
    }
  };
  const renderTags = () => (
    <div className="mt-4">
      <div className="flex gap-2">
        <input
          onKeyDown={(e) => e.key === "Enter" && addTag()}
          value={tag}
          onChange={(e) => setTag(e.target.value)}
          className="border w-full p-2 border-[#a3a3a3] rounded-lg"
          type="text"
          placeholder="Add Tag..."
        />
        <button onClick={addTag} className="bg-[#6a6a6a] text-white px-4 rounded-lg hover:opacity-80">
          Add
        </button>
      </div>
      <div className="flex flex-wrap gap-2 mt-4">
        {/* Filter out tags in removedTags before rendering */}
        {[...selectedFileTags, ...fileTags]
          .filter(tag => !removedTags.includes(tag)) // Exclude tags marked for removal
          .map((tag, index) => (
            <div key={index} className="flex items-center text-white bg-[#000000a8] font-semibold px-3 py-1 rounded-full">
              <span className="mr-2 text-base">{tag}</span>
              <div onClick={() => removeTag(tag)} className="rounded-full bg-[#fe3e3e] hover:opacity-80 cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="white" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x h-4 w-4 text-white">
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </div>
            </div>
          ))}
      </div>
    </div>
  );

  return (
    <>
      <TooltipContainer content="Tag" id="open-sidebar-tooltip">
        <Icon icon={tags} size={20} onClick={() => setIsPopupOpen(true)} className="text-[#5d5d5d] cursor-pointer" />
      </TooltipContainer>

      {isPopupOpen && (
        <Modal
          title="Tags"
          content={renderTags()}
          onClose={() => {
            setIsPopupOpen(false);
            setFileTags([])
          }} isVisible={isPopupOpen}
          onConfirm={updateFileTags}
          confirmText="Save Changes"
          buttonColor="#008000"
          cancelText="Cancel"
          data={fileTags}
          setFileTags={setFileTags}
          selectedFileTags={removedTags}
        />
      )}
    </>
  );
}


export default TagEditor;
