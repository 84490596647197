import React, { useState, useEffect } from 'react'
import RoundedButton from './Common/RoundedButton';
import { ConversationUtils } from '../utils/conversation-utils';
import Icon from 'react-icons-kit';
import { ic_file_download } from 'react-icons-kit/md/ic_file_download';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
import { filePdf } from 'react-icons-kit/icomoon/filePdf'
import { fileText2 } from 'react-icons-kit/icomoon/fileText2'
import { useAppData } from '../context/AppContext';
import ProfileAccount from './Common/ProfileAccount';
import jsPDF from 'jspdf';


function ChatHeader({ isExportingToPDF, setIsExportingToPDF, conversations, setShowCLearChatModal, disableClearConversion, printRef, loadedMessagesCount, setLoadedMessagesCount }) {
    const { authUser } = useAppData();
    const [toogle, setToggle] = useState(false)
    const [history, setHistory] = useState(false)
    const [loading, setLoading] = useState(false)

    const toggleProfileBar = () => {
        setToggle(!toogle)
    }

    const handleExportAsPDF = async () => {
        setIsExportingToPDF(true);
    
        const pdf = new jsPDF('p', 'mm', 'a4');
        const marginLeft = 10;
        const marginTop = 10;
        const maxLineWidth = 170;
        const lineHeight = 7;
        const pageHeight = pdf.internal.pageSize.height;
        const marginBottom = 25;
        let yOffset = marginTop;
    
        try {
            conversations.forEach((conversation, index) => {
                const sources = (conversation.documents?.map((file) => file.fileName)) ||
                    (conversation.files?.map((file) => file.fileName)) || [];
    
                // Query
                pdf.setFont("helvetica", "bold");
                pdf.setFontSize(13);
                pdf.text("Query: ", marginLeft, yOffset);
                const queryWidth = pdf.getTextWidth("Query: ");
    
                pdf.setFont("helvetica", "normal");
                pdf.setFontSize(12);
                const wrappedQuestion = pdf.splitTextToSize(conversation.question, maxLineWidth);
                pdf.text(wrappedQuestion, marginLeft + queryWidth, yOffset);
                yOffset += wrappedQuestion.length * lineHeight;
    
                // Model
                pdf.setFont("helvetica", "bold");
                pdf.setFontSize(12);
                pdf.text("Model: ", marginLeft, yOffset);
                const modelWidth = pdf.getTextWidth("Model: ");
    
                pdf.setFont("helvetica", "normal");
                const wrappedModel = pdf.splitTextToSize(conversation.model || 'N/A', maxLineWidth);
                pdf.text(wrappedModel, marginLeft + modelWidth, yOffset);
                yOffset += wrappedModel.length * lineHeight;
    
                // Sources
                pdf.setFont("helvetica", "bold");
                pdf.text("Sources: ", marginLeft, yOffset);
                const sourcesWidth = pdf.getTextWidth("Sources: ");
    
                pdf.setFont("helvetica", "normal");
                const wrappedSources = pdf.splitTextToSize(sources.length > 0 ? sources.join(', ') : 'N/A', maxLineWidth);
                pdf.text(wrappedSources, marginLeft + sourcesWidth, yOffset);
                yOffset += wrappedSources.length * lineHeight;
    
                // Response
                pdf.setFont("helvetica", "bold");
                pdf.text("Response: ", marginLeft, yOffset);
                const responseWidth = pdf.getTextWidth("Response: ");
    
                pdf.setFont("helvetica", "normal");
                const wrappedResponse = pdf.splitTextToSize(conversation.answer, maxLineWidth);
                pdf.text(wrappedResponse, marginLeft + responseWidth, yOffset);
                yOffset += wrappedResponse.length * lineHeight;
    
                // Add bottom padding or new page if near the page limit
                yOffset += lineHeight;
                if (yOffset > pageHeight - marginBottom) {
                    pdf.addPage();
                    yOffset = marginTop;
                } else {
                    yOffset += lineHeight; // Additional spacing for next conversation block
                }
            });
    
            const currentTime = new Date().toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-');
            const formattedDate = new Date().toISOString().split("T")[0];
            pdf.save(`conversation_export_${formattedDate}_${currentTime}.pdf`);
        } catch (error) {
            console.error("Error generating PDF:", error);
        } finally {
            setIsExportingToPDF(false);
            setLoading(false);
        }
    };
    








    return (
        <div>
            <div
                className="flex gap-2 absolute right-5 z-10 items-center"
                style={{ top: "10px", right: "20px" }}
            >
                <RoundedButton
                    label={"History"}
                    tooltip={"Download conversation"}
                    icon={<Icon icon={ic_file_download} size={18} />}
                    disabled={conversations?.length == 0}
                    onClick={() => {
                        setHistory(!history)
                        // TODO: download conversation history
                        // ConversationUtils.exportConversation(conversations);
                    }}
                />

                {history && <div className=' w-48 bg-[#ddd] border-[1px] border-[#bababa] rounded-lg  absolute top-10'>
                    <div className=' border-b-[1px] border-[#adadad] p-2'>
                        <p className=' font-semibold text-[#084c92]'>Download as</p>
                    </div>
                    <div className=' '>
                        <div onClick={() => ConversationUtils.exportConversation(conversations)} className='  py-1 px-2 gap-1 flex items-center text-[#535151] cursor-pointer hover:bg-[#d5d5d5]'>
                            <Icon icon={fileText2} size={18} />

                            <span className='font-medium ml-1'>TXT</span>
                        </div>

                        <div onClick={handleExportAsPDF} className=' py-1   gap-2 px-2 flex items-center  cursor-pointer text-[#535151] hover:bg-[#d5d5d5]'>
                            <Icon icon={filePdf} size={18} />
                            <span className='font-medium'>
                                {loading ? "Generating PDF..." : " PDF"}
                            </span>
                        </div>
                    </div>
                </div>
                }



                <RoundedButton
                    tooltip={"Clear conversation"}
                    icon={<Icon icon={ic_delete} size={18} />}
                    disabled={disableClearConversion}
                    onClick={() => setShowCLearChatModal(true)}
                />

                <button
                    data-testid="profile-button"
                    className="flex h-8 w-8 items-center justify-center rounded-full relative "
                    type="button"
                    aria-haspopup="menu"
                    aria-expanded="false"
                    data-state="closed"
                    onClick={toggleProfileBar}
                >
                    <div className="flex items-center justify-center h-full w-full rounded-full bg-[#7b1fa2]">
                        <span className="text-base font-semibold text-white">
                            {authUser?.name?.[0].toUpperCase()}
                        </span>
                    </div>
                </button>

                {toogle && <div className='absolute top-12 -right-2  '>
                    <ProfileAccount setToggle={setToggle} />
                </div>}

            </div>
        </div>
    )
}

export default ChatHeader