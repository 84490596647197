import React, { useState, useEffect } from "react";
import apiRequest from "../api/api";

import { useAppData } from "../context/AppContext";
import toast from "react-hot-toast";
import { fas } from "@fortawesome/free-solid-svg-icons";
import handleApiResponse from "../utils/handleApiResponse ";


const DocTypes = [
  { type: "App", title: "App " },
  { type: "Chat", title: "Chat" },
];

function ContactUs() {
  const { authUser } = useAppData();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [err, setErr] = useState({ name: false, email: false, message: false });

  useEffect(() => {
    // Initialize the email state with the authUser's email
    setEmail(authUser.email);
    setName(authUser.name);
  }, [authUser]);



  const submitContactForm = async () => {
    if (name == "" || email == "" || message == "") {
      toast.error("Message required", { duration: 7000 ,  style:{marginTop:"70px"}});
      setErr({
        name: name === "",
        email: email === "",
        message: message === "",
      });
      return;
    }


    try {
      const responseData = await apiRequest(
        "POST",
        "/feedback",
        { message: message, feedBackType: DocTypes[0].type }
      );
      const res = handleApiResponse(responseData)
      if (res.statusCode == 200) {
        setMessage("");
        setErr(false)
      }

    } catch (err) {
      console.log(err)
    }
  };

  return (
    <div>
      <div className="contact-screen-body mx-auto max-w-7xl">
        <h2 className="text-center text-[#f6874c] font-bold text-lg mt-2 ">
          Contact Us
        </h2>

        <div className="contact-screen-body-item ">
          <div className="contact-app-form">
            <div className="contact-app-form-group contact-message flex items-center gap-2 justify-between">
              <h6 className="text-white w-20">Name</h6>
              <input
                style={{ border: err.name ? "1px solid red" : "" }}
                className="contact-app-form-control"
                placeholder="Name"
                value={name}
                readOnly
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="contact-app-form-group contact-message flex items-center gap-2">
              <h6 className="text-white  w-20">Email</h6>

              <input
                style={{ border: err.email ? "1px solid red" : "" }}
                className="contact-app-form-control"
                placeholder="Email"
                value={email}
                readOnly
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="contact-app-form-group contact-message flex items-center gap-2">
              <h6 className="text-white  w-20 ">Message</h6>

              <textarea
                style={{ border: err.message ? "1px solid red" : "" }}
                value={message}
                placeholder="Provide your feedback here..."
                className="contact-app-form-control"
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <div className="contact-app-form-group contact-buttons flex justify-end">

              <button
                onClick={submitContactForm}
                style={{
                  background: "#f6874c",
                  color: "black",
                  fontWeight: "600",
                }}
                className="contact-app-form-button  "
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
