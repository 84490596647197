import toast from "react-hot-toast";
import { useAppData } from "../context/AppContext";
import { LocalStorageManager } from "./local-storage-manager";

const maskApiSuccessMessages = [{
  url: "/files",
  method: "get"
},

{
  url: "/files/evaluateFiles",
  method: "post"
},

{
  url: "/files/upload",
  method: "post"
},

{
  url: "/files/fileTags",
  method: "get"
},

{
  url: "/files/fileTags",
  method: "put"
},

{
  url: "/files",
  method: "delete"
},

{
  url: "/chat",
  method: "get"
},

{
  url: "/chat/ask",
  method: "post"
},

{
  url: "/chat",
  method: "delete"
},

{
  url: "/chat/featureChat",
  method: "put"
},


{
  url: "/llm/displayOptions",
  method: "get"
}, {
  url: "/llm/defaultLLm",
  method: "get"
},

{
  url: "/llm",
  method: "get"
},

{
  url: "/llm",
  method: "put"
},

{
  url: "/subscriptionPlan",
  method: "get"
},

{
  url: "/auth/signIn",
  method: "post"
},

{
  url: "/files/fileTypes",
  method: "post"
},

]

const handleApiResponse = (res) => {

  const isAdmin = LocalStorageManager.loggedInUser.isAdmin

  // Handle the success case (res.data)
  if (res.data && res.data.statusCode == 200) {
    if (!isAdmin && !maskApiSuccessMessages.find(
      (m) => res.config.url.includes(m.url) && m.method === res.config.method
    )) {
      // mask success messages
      toast.success(res.data.message, {
        duration: 7000,
        style:{marginTop:"70px"}
      }); // Show success message
    }
    return res.data; // Return the data for further use
  }

  // Handle the failure case (res.message)
  if (res.message && (res.statusCode === 400 || res.statusCode === 404 || res.statusCode === 406)) {
    console.log(res)
    const errorMessages = Array.isArray(res.message) ? res.message : [res.message];
    errorMessages.forEach((msg) => toast.error(msg), {
      duration: 7000,
      style:{marginTop:"70px"}
    }); // Show error messages
    throw new Error(errorMessages.join(', ')); // Throw error with all messages
  }

  throw new Error('Unexpected response format'); // Handle unexpected cases
};

export default handleApiResponse;