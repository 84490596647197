import React from 'react'
import logo1 from "../images/logo3.png"


function ProcessingLogin() {
    return (
        <div className=' h-svh flex justify-center text-center  flex-col '>
            <div>
            <img className='w-40 m-auto' src={logo1} />
            <div className="loader">
              <div className="outer"></div>
              <div className="middle"></div>
              <div className="inner"></div>
            </div>

            <h2 className=' text-2xl'>Processing login...</h2>
</div>
            
        </div>
    )
}

export default ProcessingLogin