
export const LocalStorageManager = {
    loggedInUser: {
        get info() {
            let userLoggedIn = localStorage.getItem("userLoggedIn");
            if (userLoggedIn) {
                userLoggedIn = JSON.parse(userLoggedIn);
            }
            return userLoggedIn;
        },
        set info(value) {
            localStorage.setItem("userLoggedIn", value);
        },
        get userName() {
            let userLoggedIn = this.info;
            return userLoggedIn && userLoggedIn.username ? `${userLoggedIn.username}` : "";
        },
        get userId() {
            let userLoggedIn = this.info;
            let userId = userLoggedIn && userLoggedIn.id;
            return userId ? userId : "";
        },
        get roles() {
            let userLoggedIn = this.info;
            let roles = userLoggedIn && userLoggedIn.roles;
            return roles && Array.isArray(roles) ? roles : [];
        },
        // get permissions() {
        //     let userLoggedIn = this.info;
        //     let permissions = userLoggedIn && userLoggedIn.permissions;
        //     return permissions ? permissions : []
        // },
        // get logicModules() {
        //     let userLoggedIn = this.info;
        //     let logicModules = userLoggedIn && userLoggedIn.logicModules;
        //     return logicModules ? logicModules : []
        // },
        get loginTime() {
            let userLoggedIn = localStorage.getItem("userLogInTime");
            if (userLoggedIn) {
                return userLoggedIn;
            }
        },
        get timeZone() {
            let userLoggedIn = this.info;
            let timeZone = userLoggedIn && userLoggedIn.timeZone;
            return timeZone ? timeZone : "Local"
        },
        set loginTime(time) {
            localStorage.setItem("userLogInTime", time);
        },
        get valid() {
            let valid = true;
            let userLoggedIn = localStorage.getItem("userLoggedIn");
            if (userLoggedIn) {
                userLoggedIn = JSON.parse(userLoggedIn);
                if (userLoggedIn) {
                    valid = valid && userLoggedIn.roles && Array.isArray(userLoggedIn.roles);
                    valid = valid && userLoggedIn.id;
                    // valid = valid && userLoggedIn.permissions;
                    // valid = valid && userLoggedIn.logicModules;
                    // valid = valid && userLoggedIn.yardGuiSettings;
                    // valid = valid && userLoggedIn.timeZone;
                }
                else {
                    valid = false;
                }
            }
            else {
                valid = false;
            }
            return valid;
        },
        get isAdmin() {
            let roles = this.roles;
            return roles?.includes("Admin");
        }
    },
    // timezone: {
    //     get tz() {
    //         let tzInStore = localStorage.getItem("timezone");
    //         var valueArray = Object.values(EnumTimeZones);
    //         if (!tzInStore || (tzInStore && !valueArray.includes(tzInStore))) {
    //             tzInStore = EnumTimeZones.Local;
    //         }
    //         return tzInStore;
    //     },
    //     set tz(value) {
    //         value && localStorage.setItem("timezone", value);
    //     },
    // },
    token: {
        get userToken() {
            return localStorage.getItem("access_token") || undefined;
        },
        set userToken(value) {
            value && localStorage.setItem("access_token", value);
        },
    },
    cleanLocalStorage() {
        localStorage.clear();
    }
}