import React, { useEffect, useState } from "react";
import apiRequest from "../api/api";
import handleApiResponse from "../utils/handleApiResponse ";
import Navbar from "./Navbar";
import Badge from "./Common/Badge";


function PrcingPlan2() {
    const [plansData, setPlansData] = useState([]);
    const getSubscriptionPlan = async () => {
        const apiData = await apiRequest("GET", "/subscriptionPlan")
        const res = handleApiResponse(apiData)
        setPlansData(res.data);
    }
    useEffect(() => {

        getSubscriptionPlan()
    }, [])
    const headers = plansData.length > 0 ? ["", ...plansData[0].plans.map(plan => plan)] : [];

    return (

        <div className="  sm:h-full xl:h-svh">
            <Navbar />
            <div className="py-20 pricing-container ">
                <div className="text-center mb-12">
                    <h1 className="text-4xl font-bold">Our Pricing Plans</h1>
                </div>

                <div className="overflow-x-auto  ">

                    <table style={{
                    }} className="min-w-full">
                        <thead>
                            <tr style={{ borderBottom: "1px solid #e5e7eb" }} className="relative">
                                {headers.map((header, index) => {
                                    console.log(header)
                                    return (
                                        <th
                                            key={index}
                                            style={{ textAlign: header.label === "Feature" ? "left" : "center", width: "120px" }}
                                            className="text-2xl font-semibold  "
                                        >
                                            {/* { header.labelBadge==true &&  (
                                                <div className="">
                                                    <Badge text={ "Coming soon"} color="bg-black" className="mb-2" />
                                                </div>
                                            )} */}

                                            {header && (
                                                <div className="">
                                                    <Badge
                                                        text={header.labelBadge ? "Coming soon" : ""} // Display based on labelBadge
                                                        color={header.labelBadge ? "bg-black" : "bg-white"}
                                                    />
                                                </div>
                                            )}
                                            {header.label}

                                        </th>
                                    )

                                })}
                            </tr>

                        </thead>

                        <tbody>
                            {plansData.map((feature, index) => (
                                <tr key={index}>
                                    <td style={{ borderBottom: "1px solid #e5e7eb" }} className="font-bold">
                                        {feature.label}
                                    </td>
                                    {feature.plans.map((plan, idx) => {
                                        const isPremiumPlan = headers[idx + 1] === "Premium Plan";
                                        return (
                                            <td
                                                key={idx}
                                                style={{
                                                    borderBottom: isPremiumPlan ? "1px solid gray" : "1px solid #e5e7eb",
                                                    background: isPremiumPlan ? "#111827" : "",
                                                    color: isPremiumPlan ? "white" : "black",


                                                }}
                                                className="p-4  text-center font-semibold"
                                            >
                                                {plan.value === "true" ? "✔" : plan.value=="false"? "X":plan.value}
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default PrcingPlan2