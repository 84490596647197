import React from 'react';
import ReactMarkdown from 'react-markdown';
import './markdownRenderer.css'
const MarkdownRenderer=({markdownContent})=>{
    return (
        <div className="markdown-container">
          <ReactMarkdown  
              children={markdownContent}  
          />
        </div>
      );
    
}

export default MarkdownRenderer;
