import React, { useState } from 'react'
import { toast } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';

import { useNavigate, useParams } from 'react-router-dom';
import apiRequest from '../api/api';
import handleApiResponse from '../utils/handleApiResponse ';

function useResetPass() {
    const { token } = useParams()
    const query = new URLSearchParams(useLocation().search);

    const navigate = useNavigate()
    


    const resetPassword = async (email, password,confirmNewPassword) => {
        const token = query.get('token');
        const success = handleInputErrors({ email, password,confirmNewPassword })

        if (!success) return;
        try {
            const res = await apiRequest("POST", `/user/reset-password?reset-token=${token}`, JSON.stringify({ email, newPassword: password }))

            handleApiResponse(res)
            navigate("/signin")
        }

        catch (err) {
            // toast.error(err.message)
        }
    }

    return { resetPassword };
}

export default useResetPass


function handleInputErrors({ email, password,confirmNewPassword }) {
    if (!email) {
        toast.error("Please provide your email address", {
            duration: 7000,style:{marginTop:"70px"}})
        return false
    }
    if (!password) {
        toast.error("Please enter new password", {
            duration: 7000,style:{marginTop:"70px"}})
        return false
    }

    if (!confirmNewPassword) {
        toast.error("Please enter confirm password", {
            duration: 7000,style:{marginTop:"70px"}})
        return false
    }

    if (password !== confirmNewPassword) {
        toast.error("Passwords mismatch", {
            duration: 7000,style:{marginTop:"70px"}})
        return false
    }


    

    return true
}