// FileList.js
import React from 'react';
import Icon from 'react-icons-kit';
import { attachment } from "react-icons-kit/icomoon/attachment";
import { ic_delete_outline } from 'react-icons-kit/md/ic_delete_outline';
import TooltipContainer from "./Common/TooltipContainer";
import SelectAllItems from './Common/SelectAllItems';
import SearchForm from './Common/SearchForm';
import TagEditor from './TagEditor';

const Files = ({
    files,
    searchTerm,
    handleSearchChange,
    togglePopup,
    loading,
    toggleSelectAll,
    userSelectedDocs = [],
    handleConversationSelection,
    handleConversationSelectionDelete,
    getImageByExtension,
    fileTags,
    setFileTags,
    fetchedFileTags,
    getFiles

}) => {
    // Filter files based on search term

    const filteredData =
        files?.length >= 1 &&
        files.filter((file) => {
            const nameToCheck = file.originalName || file.name;
            return nameToCheck && nameToCheck.toLowerCase().includes(searchTerm.toLowerCase());
        });

    return (
        <div className="file">
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                    margin: "10px 15px",
                }}
            >
                <SearchForm data={files} searchTerm={searchTerm} handleSearchChange={handleSearchChange} placeholder={"Search File Name"} />

                <TooltipContainer
                    id="add-new-files-tooltip"
                    content={"Add new files/web pages"}
                >
                    <Icon
                        icon={attachment}
                        size={24}
                        disabled={loading}
                        onClick={togglePopup}
                        className="text-[#5d5d5d]"
                    />
                </TooltipContainer>
            </div>

            <SelectAllItems filteredData={filteredData} toggleSelectAll={toggleSelectAll} selectedItems={userSelectedDocs} />


            <div
                style={{
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#f6874c grey",
                }}
                className="flex-1"
            >
                {filteredData.length > 0 && filteredData.map((file) => {
                    console.log(file)
                    const nameChunks = (file.originalName || file.name).split(".");
                    const fileType = nameChunks[nameChunks.length - 1];
                    const imageUrl = getImageByExtension(fileType);
                    const fileNames = file ? (file.originalName || file.name) : null;
                    const isSelected = userSelectedDocs.some(doc => doc.fileId === file.id);
                    const totalPages = file && file.totalPages;

                    return (
                        <li key={file.id} className="list-none cursor-pointer">
                            <div
                                className="flex justify-between items-center my-3 mx-4 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                                style={{
                                    padding: "2px 4px",
                                    backgroundColor: isSelected ? "#cfcfcf" : "",
                                }}
                            >
                                <img src={imageUrl} style={{ width: "25px" }} alt="file icon" />
                                <span
                                    title={file.originalName}
                                    style={{ wordBreak: "break-word" }}
                                    onClick={() =>
                                        !loading && handleConversationSelection(file.id, totalPages, fileNames)
                                    }
                                    className="limit-text flex-1 ms-3 font-semibold text-[#5d5d5d]"
                                >
                                    {fileNames}
                                </span>
                                
                                <div className='flex gap-2'>
                                    <TagEditor getFiles={getFiles }files={files} fetchedFileTags={fetchedFileTags} fileTags={fileTags} setFileTags={setFileTags} selectedFileId={file.id} />
                                    <TooltipContainer
                                        content={"Delete this document"}
                                        id="delete-document-tooltip"
                                    >
                                        <Icon
                                            icon={ic_delete_outline}
                                            size={24}
                                            className="text-[#d11a2a]"
                                            onClick={() =>
                                                !loading && handleConversationSelectionDelete(file.id)
                                            }
                                        />
                                    </TooltipContainer>
                                </div>

                            </div>
                        </li>
                    );
                })}
            </div>
        </div>
    );
};

export default Files;
