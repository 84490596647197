import React from 'react'

function SearchForm({ data, searchTerm, handleSearchChange, placeholder }) {
    return (
        <div>
            <form
                className="flex-1"
                style={{ opacity: data.length > 1 ? 1 : 0.3 }}
            >
                <div className="relative w-12/12">
                    <div className="absolute inset-y-0 start-3 flex items-center pointer-events-none text-[#5d5d5d]">
                        <svg
                            className="h-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                            />
                        </svg>
                    </div>
                    <input
                        disabled={data.length <= 1}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        type="search"
                        id="default-search"
                        className="block w-full py-2 px-3 pl-8 text-md text-gray-900 border-2 rounded-xl text-[#5d5d5d]"
                        placeholder={placeholder}
                        required
                    />
                </div>
            </form>
        </div>
    )
}

export default SearchForm