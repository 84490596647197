import React, { useEffect, useState, useRef } from "react";
import logo1 from "../images/logo3.png";
import menubar from "../images/menubar.png";
import useLogout from "../hooks/useLogout";
import logoutpic from "../images/logout.png";
import { useAppData } from "../context/AppContext";
import { Link, NavLink } from "react-router-dom";
import ProfileAccount from "./Common/ProfileAccount";

function Navbar() {
  const { logout } = useLogout();
  const { authUser } = useAppData();
  const [mobileMenu, isMobileMenu] = useState(false);
  const [toogle, setToggle] = useState(false)

  const username = authUser.name;
  const mobileMenuRef = useRef(null);
  const toggleButtonRef = useRef(null)

  const toggleMobileMenu = () => {
    isMobileMenu(!mobileMenu);
  };


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 770 && mobileMenu == true) {
        isMobileMenu(false);
      }

      else {
        isMobileMenu(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target) &&
        (!toggleButtonRef.current || !toggleButtonRef.current.contains(event.target))
      ) isMobileMenu(false)

    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, [mobileMenu])



  const toggleProfileBar = () => {
    setToggle(!toogle)
  }




  return (
    <nav
      style={{
        backgroundColor: "#d7d7d7",
      }}
      className=" border-gray-200 z-10 fixed w-full"
    >
      <div className=" flex flex-wrap items-center justify-between mx-auto p-3 px-4">
        <div className=" md:hidden cursor-pointer ">
          {" "}
          <img ref={toggleButtonRef} onClick={toggleMobileMenu} src={menubar} />
        </div>

        <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img className="navbvar-logo" src={logo1} alt=" Logo" />
        </a>
        <div className="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse text-black">
          <div className="flex items-center">
            {username && (
              <span className="mr-4 font-semibold"> {username}</span>
            )}
     
            <button
              data-testid="profile-button"
              className="flex h-8 w-8 items-center justify-center rounded-full relative "
              type="button"
              aria-haspopup="menu"
              aria-expanded="false"
              data-state="closed"
              onClick={toggleProfileBar}
            >
              <div className="flex items-center justify-center h-full w-full rounded-full bg-[#7b1fa2]">
                <span className="text-base font-semibold text-white">
                  {authUser?.name?.[0].toUpperCase()}
                </span>
              </div>
            </button>

            {toogle && <div className='absolute top-[75px] right-1'>
              <ProfileAccount setToggle={setToggle} authUser={authUser} />
            </div>}
          </div>
        </div>
        <div
          className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
          id="navbar-user"
        >
          <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0  dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700 navbar-links">
            <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  `block py-2 px-3 text-black md:p-0 font-semibold nav-link ${isActive
                    ? " text-secondary = border-solid border-orangeyellow border-b-2"
                    : ""
                  }`
                }
                aria-current="page"
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/conversation"
                className={({ isActive }) =>
                  `block py-2 px-3 text-black md:p-0 font-semibold nav-link  ${isActive
                    ? " text-secondary   border-solid border-orangeyellow border-b-2"
                    : ""
                  }`
                }
              >
                Conversation
              </NavLink>
            </li>



            <li>
              <NavLink
                to="/privacy-policy"
                className={({ isActive }) =>
                  `block py-2 px-3 text-black md:p-0 font-semibold nav-link ${isActive
                    ? " text-secondary  border-solid border-orangeyellow border-b-2"
                    : ""
                  }`
                }
              >
                Privacy Policy
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  `block py-2 px-3 text-black md:p-0 font-semibold nav-link ${isActive
                    ? " text-secondary  border-solid border-orangeyellow border-b-2"
                    : ""
                  }`
                }
              >
                About
              </NavLink>
            </li>
          </ul>
        </div>

        {/* for mobile */}

        {mobileMenu && (
          <div
            ref={mobileMenuRef}
            className="items-center justify-between  w-full md:flex md:w-auto absolute  left-0  pl-5 mobile-navbar-wrapper "
            id="navbar-user"
          >
            <ul className="flex flex-col font-medium md:p-0 mt-4  rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0  dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700  mobile-menu-navbar-ul">
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    `block py-1  text-black md:p-0 font-semibold nav-link ${isActive ? " text-secondary " : ""
                    }`
                  }
                  aria-current="page"
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/conversation"
                  className={({ isActive }) =>
                    `block py-1 text-black md:p-0 font-semibold nav-link  ${isActive ? " text-secondary    " : ""
                    }`
                  }
                >
                  Conversation
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/privacy-policy"
                  className={({ isActive }) =>
                    `block py-1  text-black md:p-0 font-semibold nav-link ${isActive ? " text-secondary  " : ""
                    }`
                  }
                >
                  Privacy Policy
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/about"
                  className={({ isActive }) =>
                    `block py-1 text-black md:p-0 font-semibold nav-link ${isActive ? " text-secondary  " : ""
                    }`
                  }
                >
                  About
                </NavLink>
              </li>

            </ul>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
