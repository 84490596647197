import React, { useRef, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import useLogout from '../../hooks/useLogout';
import { useAppData } from "../../context/AppContext";

function ProfileAccount({ setToggle }) {
    const { logout } = useLogout();
    const { authUser } = useAppData();


    const ref = useRef();
    const subscriptionPlan = authUser.subscriptionPlan

    const handleClickOutside = (event) => {
        // Check if the clicked target is outside the ref
        if (ref.current && !ref.current.contains(event.target)) {
            setToggle(false)
        }
    };

    const navigate = useNavigate()
    const signOut = () => {
        logout()
    }
    const handleNavigate = (url) => {
        navigate(url)
        setToggle(false)
    }

    // useEffect(() => {
    //     // Add event listener to detect clicks on the whole document
    //     document.addEventListener("mousedown", handleClickOutside);

    //     // Clean up the event listener when the component unmounts
    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, []);

    return (

        <div ref={ref} className=' w-48 bg-[#ddd] border-[1px] border-[#bababa] rounded-lg '>
            <div className=' border-b-[1px] border-[#adadad] p-2'>
                <p className=' font-semibold text-[#084c92]'>My Account ({subscriptionPlan})</p>
            </div>
            <div className=' '>
                <div onClick={() => handleNavigate("/")} className='  py-1 px-2 flex items-center text-[#535151] cursor-pointer hover:bg-[#d5d5d5]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-home">
                        <path d="M3 10L12 3l9 7v11a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-6H9v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V10z"></path>
                    </svg>
                    <span className='font-medium ml-1'>Home</span>
                </div>

                <div onClick={() => handleNavigate("/about")} className=' py-1 px-2 flex items-center  cursor-pointer text-[#535151] hover:bg-[#d5d5d5]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mail mr-2 h-4 w-4"><rect width="20" height="16" x="2" y="4" rx="2"></rect><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path></svg>
                    <span className='font-medium'>Support</span>

                </div>


                {/* <div onClick={() => handleNavigate("/pricing")} className=' py-1 px-2 flex items-center  cursor-pointer text-[#535151] hover:bg-[#d5d5d5]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mail mr-2 h-4 w-4"><rect width="20" height="16" x="2" y="4" rx="2"></rect><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path></svg>
                    <span className='font-medium'>subscription Plan</span>

                </div> */}


                <button className=' rounded-lg w-full py-1 px-2 flex items-center text-[#535151] hover:bg-[#d5d5d5]  cursor-pointer' onClick={signOut}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 shrink-0"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 4C5.44772 4 5 4.44772 5 5V19C5 19.5523 5.44772 20 6 20H10C10.5523 20 11 20.4477 11 21C11 21.5523 10.5523 22 10 22H6C4.34315 22 3 20.6569 3 19V5C3 3.34315 4.34315 2 6 2H10C10.5523 2 11 2.44772 11 3C11 3.55228 10.5523 4 10 4H6ZM15.2929 7.29289C15.6834 6.90237 16.3166 6.90237 16.7071 7.29289L20.7071 11.2929C21.0976 11.6834 21.0976 12.3166 20.7071 12.7071L16.7071 16.7071C16.3166 17.0976 15.6834 17.0976 15.2929 16.7071C14.9024 16.3166 14.9024 15.6834 15.2929 15.2929L17.5858 13H11C10.4477 13 10 12.5523 10 12C10 11.4477 10.4477 11 11 11H17.5858L15.2929 8.70711C14.9024 8.31658 14.9024 7.68342 15.2929 7.29289Z" fill="currentColor"></path></svg>
                    <span className=' font-medium ml-1 '>Log out</span>
                </button>
            </div>


        </div>
    )
}

export default ProfileAccount