import React, { useState, useEffect, useRef } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import iconarrow from "../images/sendIcon.png";
import regenerate from "../images/regenerate.png";
import appIcon from "../images/appIcon.png";
// import llmIcon from "../images/llmIcon.png";
import { toast } from "react-hot-toast";
import copy from "../images/copy.png";
import plaintickicon from "../images/plaintickicon.png";
import { Icon } from "react-icons-kit";
import { chevronDown } from "react-icons-kit/oct/chevronDown";
import { ic_person_outline_outline } from "react-icons-kit/md/ic_person_outline_outline";
import { ic_file_download } from "react-icons-kit/md/ic_file_download";
import { ic_delete } from "react-icons-kit/md/ic_delete";
import stopIcon from "../images/stop.png";
import { info } from "react-icons-kit/oct/info";

import { useAppData } from "../context/AppContext";
import TypingAnimation from "./TypingAnimation";
import { TypeAnimation } from "react-type-animation";
import RoundedButton from "./Common/RoundedButton";
import RoundedContainer from "./Common/RountedContainer";
import { ConversationUtils } from "../utils/conversation-utils";
import TooltipContainer from "./Common/TooltipContainer";
import MarkdownRenderer from "./MarkdownRenderer";
import apiRequest from "../api/api";
import { ic_feedback_outline } from "react-icons-kit/md/ic_feedback_outline";
import FeedBack from "./FeedBack";
import handleApiResponse from "../utils/handleApiResponse ";
import ChatHeader from "./ChatHeader";
import Modal from "./Modal";
import { LocalStorageManager } from "../utils/local-storage-manager";
import { getImageByExtension } from "./FileIcon";
const MIN_NO_OF_REFERENCES = 1;
const MAX_NO_OF_REFERENCES = 5;
const ADMIN_MAX_NO_OF_REFERENCES = 1000;
const INITIAL_LOADED_MESSAGES_COUNT = 5;
const MAXIMUM_QUESTION_CHARACTERS_COUNT = 4000;

function QuestionAnswers({
  loading,
  setLoading,
  conversations,
  setConversations,
  deleteConversations,
  docType

}) {
  const { authUser, userSelectedDocs, userSelectedTags } = useAppData();
  const [isExportingToPDF, setIsExportingToPDF] = useState(false);

  const DocTypes = [
    { type: "Manual", title: "Files " },
    { type: "Tag", title: "Tag" },
  ];
  const ref = useRef();
  const [copyText, setCopyText] = useState([]);
  const [question, setQuestion] = useState("");
  const [textColor, setTextColor] = useState("rgb(11, 91, 140)");

  const [typing, setTyping] = useState(false); // State to track if typing is in progress
  const [typingText, setTypingText] = useState("");
  // const [newAnswer, setNewAnswer] = useState(null);
  const [selectedModel, setSelectedModel] = useState("default");
  const [noOfReferences, setNoOfReferences] = useState(5);
  const [showSelectedSources, setShowSelectedSources] = useState(null);
  const [showCLearChatModal, setShowCLearChatModal] = useState(false); // State variable for showing delete modal
  const [isFormOpen, setIsFormOpen] = useState(false);

  const [supportedLlms, setSupportedLlms] = useState([]);
  const [loadedMessagesCount, setLoadedMessagesCount] = useState(
    INITIAL_LOADED_MESSAGES_COUNT
  );

  const disableClearConversion = !conversations?.length > 0;
  const handleClearChat = () => {
    deleteConversations();
    setShowCLearChatModal(false);
  };

  const handleCopy = (index) => {
    const newTextArray = [...copyText];
    newTextArray[index] = true;
    setCopyText(newTextArray);
  };

  useEffect(() => {
    if (ref.current) ref.current.scrollIntoView();
  }, [conversations]);


  useEffect(() => {
    if (copyText[0]) { // Assuming it's an array and you check the first item
      const timeoutId = setTimeout(() => {
        setCopyText([false]);
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [copyText]);

  // useEffect(() => {
  //   if (ref.current) {
  //     ref.current.scrollIntoView();
  //   }
  // }, [newAnswer]);


  const handleSubmit = async (e, question) => {
    e.preventDefault();
    if (ref.current) ref.current.scrollIntoView();
    const message = docType == "Tag" ? "Please select one or more Tags to proceed  " : "Please select one or more Documents to proceed"


    if (
      (docType == DocTypes[1].type && userSelectedTags.length === 0) ||
      (docType == DocTypes[0].type && userSelectedDocs.length === 0)
    ) {
      toast.error(message, { duration: 7000, style: { marginTop: "70px" } });
      return;
    }

    const requestData = {
      question: question.trim(),
      modelId: selectedModel,
      referencesCount: noOfReferences,
    };

    if (docType === "Tag") {
      // Use tags instead of documents
      requestData.chatMode = "Tag";
      requestData.fileTags = userSelectedTags;
    }

    else {
      // Use selectedDocs
      requestData.chatMode = "Manual";
      requestData.selectedDocs = userSelectedDocs?.map((file) => ({
        fileId: file.fileId,
        pageRanges: [
          {
            start: 0,
            end: file.pageRanges,
          },
        ],
      }));
    }


    if (question.trim() !== "") {
      const newQuestionData = {
        question: question.trim(),
        answer: "",
        model: selectedModel,
        documents: docType == "Tag" ? userSelectedTags : userSelectedDocs,
        noOfReferences: noOfReferences,
      };
      setLoadedMessagesCount(INITIAL_LOADED_MESSAGES_COUNT);
      setConversations((prevData) => [...prevData, newQuestionData]);
      setLoading(true); // Show loading indicator
      try {
        const responseData = await apiRequest(
          "POST",
          `/chat/ask`,
          JSON.stringify(requestData)
        );

        const res = handleApiResponse(responseData)
        if (res.statusCode == 200) {
          setLoading(false);
          setTyping(true);
          setQuestion("");

          const updatedAnswerData = {
            ...newQuestionData,
            answer: res.data.answer || "Sorry, I couldn't retrieve an answer.",
            references: res.data.references,
            retrievedKnowledge: res.data.retrievedKnowledge ?? "",
          };
          // Update the specific conversation with the answer
          setConversations((prevConversations) =>
            prevConversations.map((conv) => {
              const trimedQuestion = question.trim("")
              return conv.question == trimedQuestion ? updatedAnswerData : conv

            }
            )
          );
        }

      } catch (err) {
        console.log(err, "err");
        setLoading(false);
      }
    }
  };

  // Function to get the last question
  const getLastQuestion = () => {
    const questions = conversations?.filter((item) => item.question);
    const lastItem = questions[questions.length - 1];
    return lastItem?.question || "";
  };

  const ToggleselectedSources = (index) => {
    const newValue = showSelectedSources === index ? null : index;
    setShowSelectedSources(newValue);
  };


  const onTypingCompletion = () => {
    setTyping(false);
    // update last message answer
    let updatedConversations = [...conversations];
    updatedConversations[updatedConversations.length - 1] = {
      ...updatedConversations[updatedConversations.length - 1],
      answer: `${typingText} ...`,
    };
    setConversations([...updatedConversations]);
  };

  const noOfSelectedDocs = userSelectedDocs?.length || 0;
  const noOfSelectedTags = userSelectedTags?.length || 0;


  const selectionMessages = docType == "Tag" ? "Please select one or more tags " : "Please select one or more documents"

  const message = noOfSelectedDocs > 0
    ? `Responding from the selected ${noOfSelectedDocs} document${noOfSelectedDocs > 1 ? "s" : ""}`
    : noOfSelectedTags > 0
      ? `Responding from the selected ${noOfSelectedTags} tag${noOfSelectedTags > 1 ? "s" : ""}`
      : selectionMessages;

  useEffect(() => {
    const fetchLlmOptions = async () => {
      try {
        const responseData = await apiRequest("GET", "/llm/displayOptions");
        const res = handleApiResponse(responseData)

        if (res.statusCode == 200) {
          setSupportedLlms(res.data);
        }

      } catch (err) {
        console.error("Error fetching LLM options:", err);
      }
    };

    fetchLlmOptions();
  }, []);

  const printRef = useRef();


  useEffect(() => {
    const getDefaultLLM = async () => {
      try {
        const responseData = await apiRequest("GET", "/llm/defaultLLm");
        const res = handleApiResponse(responseData)
        if (res.statusCode === 200) {
          const defaultModelId = res.data.modelId;
          // Check if the default model is in supported LLMs
          if (supportedLlms.some(llm => llm.modelId == defaultModelId)) {
            setSelectedModel(defaultModelId); // Set the default model if it matches
          }
        }
      } catch (err) {
        console.error("Error fetching default LLM:", err);
      }
    };

    getDefaultLLM();
  }, [supportedLlms]);


  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  const loadMoreMessages = () => {
    setLoadedMessagesCount(
      (prevValue) => prevValue + INITIAL_LOADED_MESSAGES_COUNT
    );
  };

  const MAX_N_REFERENCES = LocalStorageManager.loggedInUser.isAdmin
    ? ADMIN_MAX_NO_OF_REFERENCES
    : MAX_NO_OF_REFERENCES;


  return (
    <div
      className="flex flex-col h-safe-screen overflow-y-auto"
      style={{ width: "100%", position: "relative" }}
    >
      <div
        className="absolute bg-white w-full"
        style={{ zIndex: 1, height: "58px", borderBottom: "1px solid #cdcdcd" }}
      ></div>

      <ChatHeader isExportingToPDF={isExportingToPDF} setIsExportingToPDF={setIsExportingToPDF} loadedMessagesCount={loadedMessagesCount} setLoadedMessagesCount={setLoadedMessagesCount} printRef={printRef} conversations={conversations} setShowCLearChatModal={setShowCLearChatModal} disableClearConversion={disableClearConversion} />
      <div
        ref={printRef}
        className="flex-1 overflow-y-auto relative"
        style={{
          width: "100%",
          padding: "60px 50px 10px",
          scrollbarWidth: "thin",
        }}
      >
        {loadedMessagesCount < conversations?.length ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <RoundedButton
              onClick={loadMoreMessages}
              label={"Load more messages"}
            />

            <div
              style={{
                height: "1px",
                borderTop: "2px dashed gray",
                margin: "5px",
                width: "300px",
              }}
            ></div>
            <div style={{ fontSize: "13px", fontWeight: 600 }}>
              Showing last {loadedMessagesCount} messages{" "}
            </div>
          </div>
        ) : null}
        {conversations?.length > 0 ? (
          <>
            {conversations
              .slice(-loadedMessagesCount)
              .map((data, index, arr) => {
                let modelUsed =
                  supportedLlms?.find((m) => m.modelId == data.model)
                    ?.modelName ??
                  data.model ??
                  "";

                return (
                  <React.Fragment key={data.id}>
                    {data.question && (
                      <div
                        data-message-author-role="user"
                        dir="auto"
                        className="my-6 mx-auto relative text-message "
                        style={{ maxWidth: "1000px" }}
                        key={`${data.id}_Q`}
                      >
                        <div className="flex flex-row gap-1 relative w-full ">
                          <div style={{ paddingBottom: isExportingToPDF ? "25px" : "", }} className="print-padding relative text-white font-bold max-w-[70%] rounded-xl bg-[#6f6f6f] px-5 py-2.5  dark:bg-token-main-surface-secondary">
                            {data.question}
                          </div>
                        </div>
                        <Icon
                          size={24}
                          icon={ic_person_outline_outline}
                          className="absolute -left-10 top-1 bg-secondary rounded-full p-1 text-white"
                        />
                      </div>
                    )}

                    <div
                      className="my-6 mx-auto relative"
                      style={{ maxWidth: "1000px" }}
                      key={`${data.id}_A`}
                    >
                      <div className="absolute -left-10">
                        <img className="w-8" src={appIcon} />
                      </div>

                      {data.retrievedKnowledge ? (
                        <div className=" bg-silver p-5">
                          <label className=" font-bold">
                            Retrieved Knowledge
                          </label>
                          <div>{data.retrievedKnowledge}</div>
                        </div>
                      ) : null}

                      {arr.length - 1 === index && data.answer && typing ? (
                        <TypingAnimation
                          text={data.answer}
                          setTyping={setTyping}
                          typingText={typingText}
                          typing={typing}
                          setTypingText={setTypingText}
                        />
                      ) : (
                        data.answer && (
                          <div
                            style={{
                              backgroundColor: "#ebebeb",
                            }}
                            className="rounded-xl px-2 py-1 mt-2 mb-2 pl-4"
                          >
                            {" "}
                            {data.answer.split("\n").map((line, index) => (
                              <div style={{ paddingBottom: isExportingToPDF ? "25px" : "", }} className="mt-2 answers-chat" key={index}>
                                <MarkdownRenderer markdownContent={line} />
                              </div>
                            ))}
                            <div className="p-2">
                              <span className="font-bold font-sans pr-1">
                                References:
                              </span>
                              <ul>
                                {
                                  data.references && data.references.map((ref) => {
                                    const nameChunks = (ref.fileName).split(".");
                                    const fileType = nameChunks[nameChunks.length - 1];
                                    const imageUrl = getImageByExtension(fileType);
                                    return (
                                      <li key={ref.fileId} className="px-4 sm:px-5 lg:px-6">
                                        <div className="mt-2 flex items-start">
                                          <img src={imageUrl} className="w-5 sm:w-5 lg:w-5" alt="file icon" />
                                          <span className="italic text-sm sm:text-base lg:text-lg ml-2">{ref.fileName}</span>
                                        </div>
                                        <div className="my-2 flex flex-wrap">
                                          {ref.chunks.map((chunk) => (
                                            <span
                                              key={chunk.pageNo}
                                              className="px-2 py-1 sm:px-3 sm:py-1 border m-1 border-[#c8c4c4] bg-white rounded-sm text-xs sm:text-sm lg:text-base"
                                            >
                                              {`p. ${chunk.pageNo}`}
                                            </span>
                                          ))}
                                        </div>
                                      </li>

                                    );
                                  })
                                }
                              </ul>
                            </div>
                            <div className="flex p-2 pt-4 gap-2 flex-wrap ">
                              <div style={{ display: isExportingToPDF ? "none" : "", }} className="dropdown-container">
                                <div
                                  className="cursor-pointer dc-title"
                                  onClick={() => {
                                    ToggleselectedSources(index);
                                  }}
                                >
                                  <span className="font-bold font-sans pr-1">
                                    Sources
                                  </span>
                                  <Icon className="pl-0" icon={chevronDown} />
                                </div>
                                <ul className={`dropdown-content ${showSelectedSources === index
                                  ? "show"
                                  : "hide"
                                  }`}>
                                  {data?.files ? (
                                    data.files.map((file, i) => (
                                      <li key={i}>{`- ${file.fileName}`}</li>
                                    ))
                                  ) : data?.documents ? (
                                    data.documents.map((doc, i) => (
                                      <li key={i}>{`- ${doc.fileName ? doc.fileName : doc}`}</li>
                                    ))
                                  ) : (
                                    <li>No files or documents found</li>
                                  )}
                                </ul>

                              </div>
                              <div className="flex-1"></div>

                              {index == arr.length - 1 ? (
                                <TooltipContainer
                                  id="feedback-tooltip"
                                  content={"User Feedback"}>
                                  <RoundedContainer
                                    style={{ display: isExportingToPDF ? "none" : "", padding: "3px 6px" }}

                                  >
                                    <Icon
                                      size={20}
                                      onClick={toggleForm}
                                      icon={ic_feedback_outline}
                                    />
                                  </RoundedContainer>
                                </TooltipContainer>
                              ) : (
                                ""
                              )}

                              <RoundedContainer>
                                {copyText[index] ? (
                                  // currently being copied
                                  <TooltipContainer
                                    id="copied-text-tooltip"
                                    content={"Copied"}
                                  >
                                    <img
                                      src={plaintickicon}
                                      height={20}
                                      style={{
                                        height: "20px",
                                        width: "20px",
                                      }}
                                    />
                                  </TooltipContainer>
                                ) : (
                                  // display copy icon
                                  <TooltipContainer
                                    id="copy-text-tooltip"
                                    content={"Copy Text to clipboard"}
                                  >
                                    <CopyToClipboard
                                      text={data.answer}
                                      onCopy={() => handleCopy(index)}
                                    >
                                      <img
                                        src={copy}
                                        height={20}
                                        style={{
                                          height: "20px",
                                          width: "20px",
                                        }}
                                      />
                                    </CopyToClipboard>
                                  </TooltipContainer>
                                )}
                              </RoundedContainer>

                              {index == arr.length - 1 ? (
                                <TooltipContainer
                                  id="regenerate-response-tooltip"
                                  content={"Regenerate"}
                                  style={{ display: isExportingToPDF ? "none" : "", padding: "0px 6px" }}
                                >
                                  <RoundedContainer
                                    onClick={(e) =>
                                      handleSubmit(e, getLastQuestion())
                                    }
                                  >
                                    <img
                                      height={20}
                                      src={regenerate}
                                      style={{
                                        height: "20px",
                                        width: "20px",
                                      }}
                                    />
                                  </RoundedContainer>
                                </TooltipContainer>
                              ) : null}
                              <RoundedContainer>
                                <div
                                  className="flex gap-1"
                                  style={{ paddingBottom: isExportingToPDF ? "20px" : "", }}
                                  title={`Model used to answer your question: ${modelUsed}`}
                                >
                                  {/* <img
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      objectFit: "scale-down",
                                    }}
                                    src={llmIcon}
                                  /> */}
                                  <span>{modelUsed}</span>
                                </div>
                              </RoundedContainer>
                            </div>
                          </div>
                        )
                      )}
                      {index == arr.length - 1 ? (
                        <FeedBack
                          conversation={data}
                          isFormOpen={isFormOpen}
                          setIsFormOpen={setIsFormOpen}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
          </>
        ) : (

          <TypeAnimation
            sequence={[
              "No Conversation available. Please ask anything!",
              1000,
              "Chat with Your Docs: Ask questions, and get answers. ",
              1000,
              "AI-Powered Insights: Leverage AI to extract valuable information from your documents.",
              1000,
              "Seamless Experience: Enjoy a user-friendly interface designed for effortless navigation.",
              1000,
              "",
            ]}
            speed={20}
            style={{
              fontSize: "2em",
              fontWeight: "bold",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              width: "100%",
              textAlign: "center",
              marginTop: "50px",
              color: textColor,
            }}
            repeat={Infinity}
          />
        )}
        <div ref={ref} style={{ height: "64px", position: "relative" }}>
          {loading && (
            <div className="loader">
              <div className="outer"></div>
              <div className="middle"></div>
              <div className="inner"></div>
            </div>
          )}
        </div>
      </div>

      <div className="input-form overflow-x-hidden">
        <form
          className="m-2 relative"
          onSubmit={(e) => handleSubmit(e, question)}
        >
          <div
            className="relative flex mx-auto rounded-2xl"
            style={{
              border: "2px solid #084c92",
              backgroundColor: "white",
              maxWidth: "1100px",
            }}
          >
            <div className="flex flex-col w-full">
              <textarea
                maxLength={MAXIMUM_QUESTION_CHARACTERS_COUNT}
                rows={1}
                disabled={loading || typing}
                style={{ opacity: loading ? 0.5 : 1, backgroundColor: "white" }}
                className="resize-none ml-3 flex-1 p-3 placeholder:text-gray font-medium focus:outline-none"
                type="text"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder={`Write a prompt (Max ${MAXIMUM_QUESTION_CHARACTERS_COUNT} characters)`}
                onKeyDown={(event) => {
                  if (
                    event.key === "Enter" &&
                    !event.shiftKey &&
                    question !== ""
                  ) {
                    handleSubmit(event, question);
                  }
                }}

              />
              <div className="flex justify-between items-center flex-wrap py-1 mx-8">
                <TooltipContainer
                  id="selected-docs-tooltip"
                  content={
                    userSelectedTags && userSelectedTags.length > 0
                      ? userSelectedTags.join(", ")
                      : userSelectedDocs && userSelectedDocs.length > 0
                        ? userSelectedDocs.map((doc) => doc.fileName).join(", ")
                        : ""
                  }

                >
                  <div className="info-msg-tile">
                    {message}
                  </div>

                </TooltipContainer>
                <span className="" style={{ color: question.length >= MAXIMUM_QUESTION_CHARACTERS_COUNT ? "red" : "green" }}>{question.length}/{MAXIMUM_QUESTION_CHARACTERS_COUNT}</span>

              </div>
            </div>

            <div className="flex items-center">
              {typing ? (
                <button className="pr-3" type="submit">
                  <img
                    src={stopIcon}
                    onClick={onTypingCompletion}
                    title="Stop"
                    height={28}
                    width={28}
                  />
                </button>
              ) : (
                <button
                  className="pr-3"
                  type="submit"
                  disabled={question == "" || loading}
                  style={{
                    opacity: question == "" || loading ? 0.3 : 0.7,
                  }}
                >
                  <img src={iconarrow} title="Send" height={28} width={28} />
                </button>
              )}
            </div>
          </div>
          <div className="flex justify-center p-2">
            <div className="flex flex-row items-center">
              <div
                className="flex bordered-item"
                style={{
                  backgroundColor: "white",
                  opacity: typing || loading ? 0.5 : 1,
                }}
              >
                <label className="font-semibold px-1">References:</label>
                <input
                  className="bg-[#e7e7e7] rounded-md pl-2 text-center"
                  type="number"
                  min={MIN_NO_OF_REFERENCES}
                  max={MAX_N_REFERENCES}
                  step="1"
                  value={noOfReferences}
                  onChange={(e) => {
                    const value = e.target.value;
                    setNoOfReferences(value); // Allow typing without immediate clamping
                  }}
                  onBlur={() => {
                    // Clamp the value only after typing is complete
                    let valueToSet = Number(noOfReferences);
                    if (valueToSet < MIN_NO_OF_REFERENCES) {
                      valueToSet = MIN_NO_OF_REFERENCES;
                    } else if (valueToSet > MAX_N_REFERENCES) {
                      valueToSet = MAX_N_REFERENCES;
                    }
                    setNoOfReferences(valueToSet);
                  }}
                />
              </div>
              <TooltipContainer
                id="no-of-references-info-tooltip"
                content={
                  "Adjust the number of references/chunks to be reviewed for answering the question."
                }
              >
                <Icon className="px-1" icon={info}></Icon>
              </TooltipContainer>
              <div
                className="flex bordered-item"
                title="Change Model"
                style={{
                  backgroundColor: "white",
                  opacity: typing || loading ? 0.5 : 1,
                }}
              >
                {/* <img
                  disabled={typing || loading}
                  style={{
                    cursor: "pointer",
                    width: "24px",
                    objectFit: "scale-down",
                  }}
                  src={llmIcon}
                /> */}
                <select
                  style={{
                    backgroundColor: "white",
                    border: "0",
                  }}
                  value={selectedModel}
                  disabled={typing || loading}
                  onChange={(e) => setSelectedModel(e.target.value)}
                >
                  {supportedLlms?.map((model, index) => {
                    return (
                      <option key={index} value={model.modelId}>
                        {model.modelName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <TooltipContainer
                id="change-llm-info-tooltip"
                content={
                  "Select from various available language models that best suits your need."
                }
              >
                <Icon className="px-1" icon={info}></Icon>
              </TooltipContainer>
            </div>
          </div>
          <p
            className=" text-center text-secondary"
            style={{ fontWeight: "bold", fontSize: "12px" }}
          >
            Chat with Docs can make mistakes, so always verify important
            information.
          </p>
        </form>
      </div>

      {showCLearChatModal && (
        <Modal
          title="Delete Message History"
          content="Are you sure you want to delete all past messages?"
          isVisible={showCLearChatModal}
          onClose={() => setShowCLearChatModal(false)}
          onConfirm={handleClearChat}
          confirmText="Delete"
          cancelText="Cancel"
          data={conversations} />


      )}
    </div>
  );
}

export default QuestionAnswers;
