import { useEffect,useState } from 'react';
import Navbar from './Navbar'
import ContactUs from './ContactUs'
import { useAppData } from '../context/AppContext';
import { useLocation, useNavigate } from 'react-router-dom';

function About() {


    return (

        <div style={{ background: "radial-gradient(circle at 1.8% 4.8%, rgb(5, 30, 58) 0%, rgb(20, 23, 34) 90%)"}}  >
             <Navbar />

            <section  id="features"
                className="relative  block px-6 py-10 md:py-20 md:px-10  border-t about-wrapper ">


                <div className="relative mx-auto max-w-5xl text-center text-white">
                    <span className="text-gray-400 my-3 flex items-center justify-center font-medium uppercase tracking-wider">
                        Why choose us
                    </span>
                    <h2
                        className="block w-full bg-gradient-to-b from-white to-gray-400 bg-clip-text font-bold text-orangeyellow text-3xl sm:text-4xl">
                        Experience the Future of Document Interaction
                    </h2>
                 
                </div>


                <div className="relative mx-auto max-w-7xl  grid grid-cols-1 gap-10 pt-14 sm:grid-cols-1 lg:grid-cols-3">

                    <div className="rounded-md border border-neutral-800 bg-neutral-900/50 p-8 text-center shadow  border-orangeyellow" >

                        <div className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md border "
                            style={{ backgroundImage: 'linear-gradient(rgb(255, 255, 229) 0%, rgb(255, 165, 0) 100%)', borderColor: 'rgb(0, 100, 255)' }}>

                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-color-swatch" width="24"
                                height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M19 3h-4a2 2 0 0 0 -2 2v12a4 4 0 0 0 8 0v-12a2 2 0 0 0 -2 -2"></path>
                                <path d="M13 7.35l-2 -2a2 2 0 0 0 -2.828 0l-2.828 2.828a2 2 0 0 0 0 2.828l9 9"></path>
                                <path d="M7.3 13h-2.3a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h12"></path>
                                <line x1="17" y1="17" x2="17" y2="17.01"></line>
                            </svg>
                        </div>
                        <h3 className="mt-6 text-white font-bold">Free to Use</h3>
                        <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide  text-white">Explore all the features of our app at no cost for evaluation purposes. We believe in providing value to our users without any financial barriers during the evaluation period.
                        </p>
                    </div>

                    <div className="rounded-md border border-neutral-800 bg-neutral-900/50 p-8 text-center shadow bg-orangeyellow border-white">
                        <div className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md border "
                            style={{ backgroundImage: 'linear-gradient(rgb(255, 255, 229) 0%, rgb(255, 165, 0) 100%)', borderColor: 'white' }}>                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bolt" width="24"
                                height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <polyline points="13 3 13 10 19 10 11 21 11 14 5 14 13 3"></polyline>
                            </svg>
                        </div>
                        <h3 className="mt-6 font-bold">Support for Multiple Document Types</h3>
                        <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-400">Our app supports a wide range of document formats including PDF, Word, Excel, and more, making it versatile.
                        </p>
                    </div>


                    <div className="rounded-md border border-neutral-800 bg-neutral-900/50 p-8 text-center shadow border-orangeyellow" >

                        <div className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md border "
                            style={{ backgroundImage: 'linear-gradient(rgb(255, 255, 229) 0%, rgb(255, 165, 0) 100%)', borderColor: 'rgb(0, 100, 255)' }}>                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-tools" width="24"
                                height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M3 21h4l13 -13a1.5 1.5 0 0 0 -4 -4l-13 13v4"></path>
                                <line x1="14.5" y1="5.5" x2="18.5" y2="9.5"></line>
                                <polyline points="12 8 7 3 3 7 8 12"></polyline>
                                <line x1="7" y1="8" x2="5.5" y2="9.5"></line>
                                <polyline points="16 12 21 17 17 21 12 16"></polyline>
                                <line x1="16" y1="17" x2="14.5" y2="18.5"></line>
                            </svg>


                        </div>
                        <h3 className="mt-6  text-white font-bold">Fast and Efficient</h3>
                        <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide  text-white">
                            Experience lightning-fast responses and seamless interaction with your documents, thanks to our advanced AI technology.
                        </p>
                    </div>


                </div>
                 
                <ContactUs/>

            </section>
        </div>
    )
}

export default About