import logo from "./logo.svg";
import "./App.css";
import Signup from "./components/Signup";
import SignIn from "./components/SignIn";
import {
  Route,
  Routes,
  BrowserRouter,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useAppData } from "./context/AppContext";
import ForgetPassoword from "./components/ForgetPassoword";
import ResetPassword from "./components/ResetPassword";
import Home from "./pages/Home";
import Conversation from "./components/Conversation";
import { useEffect } from "react";
import About from "./components/About";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ProtectedRoute from "./components/ProtectedRoutes";
import AdminPage from "./pages/admin/AdminPage";
import PricingPlans from "./components/PricingPlans";
import EmailVerification from "./components/EmailVerification";
import ResendVerification from "./components/ResendVerification";
import GoogleAuthCallback from "./components/GoogleAuthCallback";
import PrcingPlan2 from "./components/PrcingPlan2";
function App() {
  const navigate = useNavigate();
  const { authUser } = useAppData();

  return (
    <div>
      <Routes>
        {/* Public Routes */}
        <Route
          path="/signin"
          element={authUser ? <Navigate to="/" /> : <SignIn />}
        />

        <Route
          path="/user/auth/verify-email"
          element={<EmailVerification />}
        />

        <Route
          path="/resend-verification-email"
          element={<ResendVerification />}
        />
        <Route path="/auth/google/callback" element={<GoogleAuthCallback />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route
          path="/signup"
          element={authUser ? <Navigate to="/" /> : <Signup />}
        />{" "}
        <Route path="/forgot-password" element={<ForgetPassoword />} />
        <Route path="/auth/forgotPassword/reset-password" element={<ResetPassword />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/pricing" element={<PrcingPlan2 />} />
        {/* Protected Routes */}
        <Route path="/" element={<ProtectedRoute element={Home} />} />
        {/* <Route
            path="/conversation/:id"
            element={<ProtectedRoute element={Conversation} />}
          /> */}
        <Route
          path="/conversation"
          element={<ProtectedRoute element={Conversation} />}
        />
        {/* Catch-all Route */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

      <Toaster />
    </div>
  );
}

export default App;
