import axios from 'axios';
import React, { useState } from 'react'
import logo1 from "../images/logo3.png"
import useForgotPass from '../hooks/useForgotPass';
import { Link } from 'react-router-dom';

function ForgetPassoword() {
    const { forgotPassword } = useForgotPass()
    const [email, setEmail] = useState("");
    const handleClickForgotPass = async (e) => {
        e.preventDefault()
        await forgotPassword(email)
    }

    return (
        <div class="flex min-h-full  h-safe-screen flex-col justify-center px-6 py-28 lg:px-8 SignupWrapper ">
            <div class="bg"></div>
            <div class="bg bg2"></div>
            <div class="bg bg3"></div>

            <div class="sm:mx-auto sm:w-full sm:max-w-sm">
                <img class="mx-auto  " src={logo1} alt="Your Company" style={{ width: "260px" }} />
                <h2 class="mt-5 text-center text-2xl  font-bold  tracking-tight text-gray-900 text-orangeyellow">Forgot Password</h2>
            </div>

            <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm z-10 relative">
                <form class="space-y-4" method="POST">
                    <div>
                        <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                        <div class="mt-2">
                            <input value={email} onChange={(e) => { setEmail(e.target.value) }} id="email" name="email" type="email" autocomplete="email" required class=" px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>
                    <div>
                        <button onClick={handleClickForgotPass} type="submit" class=" bg-secondary  flex w-full justify-center rounded-md  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Submit</button>
                    </div>
                    <p class=" text-center text-sm text-gray-500">
                        Already have a password? {" "}
                        <Link to="/signin" class="font-semibold leading-6 text-secondary">Sign In</Link>
                    </p>
                </form>


            </div>
        </div>

    )
}

export default ForgetPassoword