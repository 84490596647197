import React, { useState } from 'react'
import Icon from 'react-icons-kit';
import { tags } from 'react-icons-kit/fa/tags'
import { useAppData } from '../context/AppContext';


function SideBarContent({ tabs, docType, setDocType, getFilesTags, fetchFiles,files }) {
    const { userSelectedTags, setUserSelectedTags, setUserSelectedDocs, userSelectedDocs } = useAppData();

    const [activeTab, setActiveTab] = useState(tabs[0]?.id || 1);  // Default to the first tab

    const handleClickTab = (tab) => {
        setActiveTab(tab.id);

        if (tab.label == "Tags") {
            // Save selected files before resetting
            getFilesTags()
            localStorage.setItem("selectedFiles", JSON.stringify(userSelectedDocs));

            // Reset files and switch to tags
            setUserSelectedDocs([]);
            setDocType("Tag");

            // Retrieve previously selected tags if available
            const savedTags = JSON.parse(localStorage.getItem("selectedTags")) || [];
            setUserSelectedTags(savedTags);
        } else {
            fetchFiles()
            // Save selected tags before resetting
            localStorage.setItem("selectedTags", JSON.stringify(userSelectedTags));

            // Reset tags and switch to files
            setUserSelectedTags([]);
            setDocType("Files");

            // Retrieve previously selected files if available
            const savedFiles = JSON.parse(localStorage.getItem("selectedFiles")) || [];
            setUserSelectedDocs(savedFiles);
        }
    };

    return (
        <div>
            <div className="w-full">
                <div className="relative right-0 text-[#505050]">
                    <ul className="rounded-xl relative flex flex-wrap p-1 list-none mt-2 bg-[#d6d6d6]" role="tablist" aria-label="Tabs">
                        {tabs.map((tab) => (
                            <li key={tab.id} className="z-30 flex-auto text-center m-0">
                                <button
                                    className={`z-30 flex items-center justify-center w-full px-0 py-1 text-sm mb-0 transition-all ease-in-out border-0 rounded-md cursor-pointer font-semibold ${activeTab === tab.id ? 'bg-white' : ''}`}
                                    onClick={() => handleClickTab(tab)}
                                    role="tab"
                                    aria-selected={activeTab === tab.id}
                                >
                                    <Icon icon={tab.icon} size={23} className="cursor-pointer" />
                                    <span className="ml-1">{tab.label}</span>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="mt-3 text-black">
                    {tabs.map((tab) =>
                        activeTab === tab.id ? (
                            <div key={tab.id} id={`tabs-with-icons-item-${tab.id}`}>
                                {tab.content}
                            </div>
                        ) : null
                    )}
                </div>
            </div>
        </div>
    );
}

export default SideBarContent;

