// src/api.js
import axiosInstance from './axiosInstance';

const apiRequest = async (
  method,
  url,
  data = null,
  params = null,
  isFormData = false,
) => {
  try {
    const config = {
      method,
      url,
      data,
      params,
    };

    // Set Content-Type header if not FormData
    if (!isFormData && data && method === 'POST') {
      config.headers = { 'Content-Type': 'application/json' };
    }

    const response = await axiosInstance(config);

    return response; // Return only the data part of the response
  } catch (error) {
    // Handle errors, log, or rethrow
    console.error(`API Error: ${error.message}`);

    // throw error; // Rethrow to handle in component
  }
};

export default apiRequest;
