
import { useNavigate } from 'react-router-dom'
import { useAppData } from '../context/AppContext'

function useLogout() {
    const navigate = useNavigate()
    const { setAuthUser } = useAppData()
    const logout = async () => {
        try {
            localStorage.clear()
            setAuthUser(null)
            navigate("/signin")
        }

        catch (err) {
            // toast.error(err.message)
        }

    }

    return { logout };

}

export default useLogout