import React, { useState, useEffect } from "react";
import SideBar from "./SideBar";
import QuestionAnswers from "./QuestionAnswers";
import { apiEndPoint, publicIP } from "./configuratiion";
import { useAppData } from "../context/AppContext";
import apiRequest from "../api/api";
import handleApiResponse from "../utils/handleApiResponse ";

function Conversation() {
  const [conversations, setConversations] = useState([]);
  const [loadingText, setLoadingText] = useState(false);
  const [loading, setLoading] = useState(false);

  const DocTypes = [
    { type: "Manual", title: "Files " },
    { type: "Tag", title: "Tag" },
  ];
  const [docType, setDocType] = useState(DocTypes[0].type);


  useEffect(() => {
    const fetchConversations = async () => {
      try {
        // Fetch user's files
        const responseData = await apiRequest("GET", `/chat`, {
        });
        const res = handleApiResponse(responseData)
        if (res.statusCode == 200) {
          setConversations(res.data);

        }

      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    fetchConversations();
  }, []);

  const deleteConversations = async () => {
    try {
      const responseData = await apiRequest("DELETE", `/chat`)
      const res = handleApiResponse(responseData)
      if (res.statusCode == 200) {
        setConversations([]);
      }
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <div className="">

      <div className="main-sidebar-chat-box flex-1">
        <SideBar
          loading={loading}
          setLoading={setLoading}
          docType={docType}
          setDocType={setDocType}
        />

        <QuestionAnswers
          loadingText={loadingText}
          setLoadingText={setLoadingText}
          loading={loading}
          setLoading={setLoading}
          conversations={conversations}
          setConversations={setConversations}
          deleteConversations={deleteConversations}
          docType={docType}

        />
      </div>
    </div>
  );
}

export default Conversation;
