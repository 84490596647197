import React, { useEffect, useState } from 'react'
import { tags } from 'react-icons-kit/fa/tags'
import TooltipContainer from './Common/TooltipContainer'
import Icon from 'react-icons-kit'
import SelectAllItems from './Common/SelectAllItems';
import SearchForm from './Common/SearchForm';
import { useAppData } from '../context/AppContext';

function TagsList({ searchTerm, handleSearchChange, fetchedFileTags, loading }) {
    const { userSelectedTags, setUserSelectedTags } = useAppData();

    const filteredData =
        fetchedFileTags?.length >= 1 &&
        fetchedFileTags.filter((tag) => {
            return tag && tag.toLowerCase().includes(searchTerm.toLowerCase());
        });

    const handleTagClick = (tag) => {
        setUserSelectedTags((prevSelected) => {
            const updatedSelection = prevSelected.includes(tag)
                ? prevSelected.filter((t) => t !== tag) // Remove tag if already selected
                : [...prevSelected, tag]; // Add tag if not selected

            // Store updated selection in localStorage
            localStorage.setItem("selectedTags", JSON.stringify(updatedSelection));
            return updatedSelection;
        });
    };

    // Toggle select/deselect all tags
    const toggleSelectAll = () => {
        // Get the current list from localStorage
        const storedSelection = JSON.parse(localStorage.getItem("selectedTags")) || [];

        if (storedSelection.length === filteredData.length) {
            setUserSelectedTags([]); // Deselect all if all are already selected
            localStorage.setItem("selectedTags", JSON.stringify([]));

        } else {
            setUserSelectedTags(filteredData); // Select all tags

            localStorage.setItem(
                "selectedTags",
                JSON.stringify(filteredData)
            );
        }
    };


    return (
        <div>
            <div className="file">
                <div style={{ margin: "10px 15px" }}>
                    <SearchForm data={fetchedFileTags} searchTerm={searchTerm} handleSearchChange={handleSearchChange} placeholder={"Search File Tag"} />
                </div>

                <SelectAllItems filteredData={filteredData} toggleSelectAll={toggleSelectAll} selectedItems={userSelectedTags} />

                <div
                    style={{
                        overflowY: "auto",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#f6874c grey",
                    }}
                    className="flex-1"
                >
                    {filteredData.length > 0 && filteredData.map((tag) => {
                        const isSelected = userSelectedTags.includes(tag)
                        return (
                            <li key={tag} className="list-none cursor-pointer">
                                <div
                                    className="flex justify-between items-center my-3 mx-4 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                                    style={{
                                        padding: "2px 4px",
                                        backgroundColor: isSelected ? "#cfcfcf" : "",
                                    }}
                                >
                                    <TooltipContainer
                                        content={"File Tag"}
                                        id={"open-sidebar-tooltip"}
                                    >
                                        <Icon
                                            icon={tags}
                                            size={20}
                                            className="text-[#5d5d5d] cursor-pointer"
                                        />

                                    </TooltipContainer>

                                    <span
                                        title={tag}
                                        onClick={() => !loading && handleTagClick(tag)}
                                        style={{ wordBreak: "break-word" }}
                                        className="limit-text flex-1 ms-3 font-semibold text-[#5d5d5d]"
                                    >
                                        {tag}
                                    </span>
                                </div>
                            </li>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}

export default TagsList