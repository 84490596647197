import React, { useState } from 'react'
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useAppData } from '../context/AppContext';
import { apiEndPoint, publicIP } from '../components/configuratiion';
import apiRequest from '../api/api';
import handleApiResponse from '../utils/handleApiResponse ';

function useSignUp() {
    const navigate = useNavigate()
    const { setAuthUser } = useAppData()
    const [loading, setLoading] = useState(false)
    const signup = async (name, email, password, confirmPassword) => {
        const success = handleInputErrors({ name, email, password, confirmPassword })

        if (!success) return;
        setLoading(true)
        try {
            const res = await apiRequest("POST", `/user`, JSON.stringify({ name, email, password }))
           
            handleApiResponse(res)

            navigate("/user/auth/verify-email")
        }
        catch (err) {
            console.log(err)
        
        }

        finally {
            setLoading(false)

        }

    }

    return { loading, signup };
}

export default useSignUp


function handleInputErrors({ name, email, password, confirmPassword }) {
    if (!name || !email || !password || !confirmPassword) {
        toast.error("Please fill in all required fields", {
            duration: 7000,style:{marginTop:"70px"}})
        return false
    }

    if (password !== confirmPassword) {
        toast.error("passwords do not match", {
            duration: 7000,style:{marginTop:"70px"}})
        return false;
    }


    if (password.length < 6) {
        toast.error("passwords must be at least 6 characters", {
            duration: 7000,style:{marginTop:"70px"}})
        return false;
    }

    return true
}