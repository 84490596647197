export function getServerEndpoint() {

  if (process.env.REACT_APP_DEV_SERVER_URL) {

    return process.env.REACT_APP_DEV_SERVER_URL;
  }

  let serverEndpoint = `${window.location.protocol}//${window.location.hostname}:${3000}/api`;
  if (process.env.NODE_ENV === "production") {
    serverEndpoint = `${window.location.protocol}//${window.location.host}/api`;
  }

  return serverEndpoint;
}
