import React, { useState } from 'react'
import logo1 from "../images/logo3.png"
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import useResetPass from '../hooks/useResetPass';

function ResetPassword() {
    const { resetPassword } = useResetPass()

    const [password, setPassword] = useState("");
    const [confirmNewPassword, setconfirmNewPassword] = useState("");

    const [email, setEmail] = useState("");

    const handleClickResetPass = async (e) => {
        e.preventDefault()
        await resetPassword(email, password,confirmNewPassword)

    }

    return (
        <div class="flex min-h-full  h-safe-screen flex-col justify-center px-6 py-28 lg:px-8 SignupWrapper">
            <div class="bg"></div>
            <div class="bg bg2"></div>
            <div class="bg bg3"></div>

            <div class="sm:mx-auto sm:w-full sm:max-w-sm">
                <img class="mx-auto " style={{ width: "260px" }} src={logo1} alt="Your Company" />
                <h2 class="mt-5 text-center text-2xl font-bold  tracking-tight text-orangeyellow">Reset Password</h2>
            </div>

            <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm z-10 relative">
                <form class="space-y-4" method="POST">

                    <div>
                        <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Email *</label>
                        <div class="mt-2">
                            <input value={email} onChange={(e) => { setEmail(e.target.value) }} id="email" name="email" type="text" required class=" px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>




                    <div>
                        <label for="password" class="block text-sm font-medium leading-6 text-gray-900">New Password *</label>
                        <div class="mt-2">
                            <input value={password} onChange={(e) => { setPassword(e.target.value) }} id="password" name="password" type="password" autocomplete="current-password" required class=" px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>

                    <div>
                        <label for="newpassword" class="block text-sm font-medium leading-6 text-gray-900">Confirm New Password *</label>
                        <div class="mt-2">
                            <input value={confirmNewPassword} onChange={(e) => { setconfirmNewPassword(e.target.value) }} id="newpassword" name="New Password" type="password" autocomplete="current-password" required class=" px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>


                    <div>
                        <button onClick={handleClickResetPass} type="submit" class="flex w-full justify-center rounded-md  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm  bg-secondary">Reset Password</button>
                    </div>

                    <p class=" text-center text-sm text-gray-500">
                        Reset token expired?
                        <Link to="/forgot-password" class="font-semibold leading-6 text-secondary"> Resend Email</Link>
                    </p>


                </form>


            </div>
        </div>

    )
}

export default ResetPassword