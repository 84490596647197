import React, { useState, useEffect } from "react";
import apiRequest from "../../api/api";
import { edit } from "react-icons-kit/fa/edit";
import Icon from "react-icons-kit";
import { toast } from "react-hot-toast";
import handleApiResponse from "../../utils/handleApiResponse ";
import { faL } from "@fortawesome/free-solid-svg-icons";

function LLMSTable() {
  const [llms, setLlms] = useState([]);
  const [editedLlms, setEditedLlms] = useState([]); // Track edited LLMS
  const [editMode, setEditMode] = useState({}); // Track which row is in edit mode
  const [defaultLlm, setDefaultLlm] = useState(null);



  const fetchLlms = async () => {
    try {
      const responseData = await apiRequest("GET", "/llm");
      const res = handleApiResponse(responseData)
      console.log(res)
      if (res.statusCode == 200) {
        setLlms(res.data);
        setEditedLlms(res.data);

      }

    } catch (error) {
      console.error("Error fetching LLMS data:", error);
    }
  };

  useEffect(() => {
    fetchLlms();
  }, []);

  // Handle changes to enabled field
  const handleEnabledChange = (id, disabled) => {
    setEditedLlms(
      editedLlms.map((llm) => (llm.modelId === id ? { ...llm, disabled } : llm))
    );
  };

  // Handle changes to API key
  const handleApiKeyChange = (id, value) => {

    setEditedLlms((prevLlms) =>
      prevLlms.map((llm) => (llm.modelId === id ? { ...llm, apiKey: value } : llm))
    );

  
  };

  const handleEditClick = (id) => {
    setEditMode((prev) => ({
      ...prev,
      [id]: !prev[id], // Toggle edit mode for the clicked row
    }));
  };



  const handleUpdate = async (llm) => {
    try {
      // Send updated LLMS data to the server
      const apiData = await apiRequest("PUT", "/llm", { modelId: llm.modelId, apiKey: llm.apiKey, disabled: llm.disabled });
      const res = handleApiResponse(apiData)
      if (res.statusCode == 200) {
        // fetchLlms();
        setEditMode((prev) => ({
          ...prev,
          [llm.modelId]: false, // Toggle edit mode for the clicked row
        }));
      }



    }

    catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      <h3 className=" text-xl font-semibold mb-2">LLM Models</h3>
      <div className="w-4/5 ">
        <table className="min-w-full bg-[#bcbcbc] ">
          <thead>
            <tr>
              <th className="text-left py-3 px-4 border-b border-gray-300">
                Vendor
              </th>
              <th className="text-left py-3 px-4 border-b border-gray-300">
                Model
              </th>
              <th className="text-left py-3 px-4 border-b border-gray-300">
                Disabled
              </th>
              <th className="text-left py-3 px-4 border-b border-gray-300">
                API Key
              </th>
            </tr>
          </thead>
          <tbody>
            {llms?.map((llm) => {
              const editedLlm = editedLlms.find((e) => e.modelId === llm.modelId) || llm;

              return (
                <tr key={llm.id}>
                  <td className="py-2 px-4 ">{llm.vendor}</td>
                  <td className="py-2 px-4 ">{llm.modelName}</td>
                  <td className="py-2 px-4 ">
                    <input
                      type="checkbox"
                      checked={
                        editedLlms.find((e) => e.modelId === llm.modelId)?.disabled || false
                      }
                      onChange={(e) =>
                        handleEnabledChange(llm.modelId, e.target.checked)
                      }
                      className="form-checkbox"
                    />
                  </td>
                  <td className="py-2 px-4  flex justify-between gap-3">
                    {editMode[llm.modelId] ? (
                      <input
                        type="text"
                        value={
                          editedLlms.find((e) => e.modelId === llm.modelId)?.apiKey || ""
                        }
                        onChange={(e) =>
                          handleApiKeyChange(llm.modelId, e.target.value)
                        }
                        className="form-input flex-1 px-2"
                      />
                    ) : (
                      <p className=" flex-1">{editedLlm.apiKey}</p>
                    )}
                    <button
                      onClick={() => handleEditClick(llm.modelId)}
                      className="text-blue-500"
                    >
                      <Icon icon={edit} />
                    </button>

                    {editMode[llm.modelId]?<button  onClick={() => handleUpdate(editedLlm)} className=" bg-[#6e6e6e] p-1 rounded-lg">Update</button>:""}
                  </td>

                  {/* <td className="py-2 px-4 border-b border-gray-300">
              
                </td> */}
                </tr>
              )


            })}
          </tbody>
        </table>
        {/* <div className="flex justify-between mt-5 items-center">
          <div className="flex items-center gap-4">
            <label htmlFor="defaultLlm" className="  font-bold ">
              Default LLM
            </label>
            <select
              id="defaultLlm"
              value={defaultLlm || ""}
              onChange={handleDefaultChange}
              className="form-select "
            >
              <option value="">Select Default LLM</option>
              {enabledLlms?.map((llm) => (
                <option key={llm.id} value={llm.modelId}>
                  {llm.modelName}
                </option>
              ))}
            </select>
          </div>

          <div className="">
            <button
              onClick={handleApply}
              className=" bg-[#343434] text-white px-6 py-2"
            >
              Apply
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default LLMSTable;
