import React, { useState } from "react";
import RoundedContainer from "./Common/RountedContainer";
import Rating from "./Rating";
import { useAppData } from "../context/AppContext";
import apiRequest from "../api/api";
import toast from "react-hot-toast";
import handleApiResponse from "../utils/handleApiResponse ";

const DocTypes = [
  { type: "App", title: "App " },
  { type: "Chat", title: "Chat" },
];

const FeedBack = ({ isFormOpen, setIsFormOpen, conversation }) => {
  const [feedback, setFeedback] = useState("");
  const [rating, setRating] = useState(0);


  // Handle feedback change
  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const onCloseFeedback = () => {
    setIsFormOpen(false);
  };

  // Submit function to send data
  const handleSubmitFeedBack = async () => {
    if (feedback == "") {
      toast.error("Please provide feedback!", { duration: 7000 ,  style:{marginTop:"70px"}});
      return;
    }

    setIsFormOpen(false);

    try {
      const responseData = await apiRequest(
        "POST",
        "/feedback",
        { message: feedback, feedBackType: DocTypes[1].type, rating: rating, chatId: conversation.id }
      );

      const res = handleApiResponse(responseData)
      if (res.statusCode == 200) {
        //  clear the form after submission
        setRating(0);
        setFeedback("");
      }
    } catch (err) {
      console.log(err);
    }


  };

  return (
    <>
      {isFormOpen && (
        <div
          className={`feedback-form ${isFormOpen ? "open" : "feedback-form-close"
            } relative rounded-xl`}
        >
          <div className="feedback-form-container">
            <div className="flex justify-between items-center">
              <h3 className="font-bold">Provide Feedback</h3>
              <Rating rating={rating} setRating={setRating} />
            </div>
            <form>
              <div style={{ width: "100%" }}>
                <textarea
                  value={feedback}
                  onChange={handleFeedbackChange}
                  className="feedback-textarea rounded-md p-2 w-full h-28"
                  id="feedback"
                  placeholder="Type your feedback here..."
                ></textarea>
              </div>
            </form>
            <div className="flex justify-between">
              <button
                onClick={onCloseFeedback}
                className="mt-2 py-2 px-4 text-[#878787] font-bold rounded-md cursor-pointer border border-[#c9c9c9]"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmitFeedBack}
                className="mt-2 py-2 px-4 bg-[#878787] text-white font-bold rounded-md cursor-pointer"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default FeedBack;
