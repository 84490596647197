import React, { useState } from 'react'
import { toast } from 'react-hot-toast';
import apiRequest from '../api/api';
import { useAppData } from '../context/AppContext';
import handleApiResponse from '../utils/handleApiResponse ';
import { useNavigate } from 'react-router-dom';
import { LocalStorageManager } from '../utils/local-storage-manager';
import { Roles } from './useLogin';
function useEmailVerification() {
    const navigate = useNavigate();
    const { setAuthUser } = useAppData();
    const verifyEmail = async (email, code) => {
      const success = handleInputErrors({ email, code });
      if (!success) return;
      try {
        const res = await apiRequest(
          "POST",
          `/user/validateCode`,
          JSON.stringify({ email: email, verificationCode: code })
        );
        const parsedResponse = handleApiResponse(res);
        // Ensure that the response has the data and access_token fields
        if (parsedResponse && parsedResponse.data && parsedResponse.access_token) {
          setAuthUser(parsedResponse.data); // Use the parsed data to set the authenticated user
          LocalStorageManager.loggedInUser.info = JSON.stringify(parsedResponse.data);
          LocalStorageManager.token.userToken = parsedResponse.access_token; // Set access token here
          navigate("/");
        }
      } catch (err) {
        console.error(err.message);
        // Optionally handle error with a toast notification or UI message
        // toast.error(err.message);
      }
    };
    return { verifyEmail };
  }
export default useEmailVerification
function handleInputErrors({ email, code }) {
    if (!email) {
        toast.error("Please provide your email address",{ duration: 7000,  style:{marginTop:"70px"}})
        return false
    }
    if (!code) {
        toast.error("Please enter your verification code",{ duration: 7000,  style:{marginTop:"70px"}})
        return false
    }
    return true
}