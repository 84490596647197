import React, { useState } from 'react'
// import logo1 from "../images/logo1.png"
import logo2 from "../images/logo3.png"
import google from "../images/google.svg"
import { Link } from 'react-router-dom';
import useLogin from '../hooks/useLogin';
import apiRequest from '../api/api';
import handleApiResponse from '../utils/handleApiResponse ';
import { getServerEndpoint } from '../getServerEndPoint';


function SignIn() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { login, loading } = useLogin()

    const handleClickSignUp = async (e) => {
        e.preventDefault();
        await login(email, password)
    }

    const handleClickGoogleAuth = () => {
        window.location.href = `${getServerEndpoint()}/auth/google`
    }



    return (

        <div class="flex min-h-full  h-safe-screen flex-col justify-center px-6 py-28 lg:px-8 SignupWrappe">

            <div class="bg"></div>
            <div class="bg bg2"></div>
            <div class="bg bg3"></div>

            <div class="sm:mx-auto sm:w-full sm:max-w-sm">
                <img class="mx-auto  w-14" src={logo2} alt="Your Company" style={{ width: "260px" }} />
                <h2 class="mt-5 text-center text-2xl font-bold  tracking-tight text-gray-900 text-orangeyellow">Sign In </h2>
            </div>

            <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm z-10 relative">
                <form class="space-y-4" action="#" method="POST">
                    <div>
                        <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address *</label>
                        <div class="mt-2">
                            <input value={email} onChange={(e) => { setEmail(e.target.value) }} id="email" name="email" type="email" autocomplete="email" required class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>

                    <div>
                        <div class="flex items-center justify-between">
                            <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Password *</label>

                        </div>


                        <div class="mt-2">
                            <input value={password} onChange={(e) => { setPassword(e.target.value) }} id="password" name="password" type="password" autocomplete="current-password" required class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>
                    {/* <div class="text-sm text-right">
                        <Link to="/forgot-password" class="font-semibold  text-secondary">Forgot password?</Link>
                    </div> */}



                    <p class=" text-right text-sm text-gray-500">
                        <Link to="/forgot-password" class="font-semibold leading-6 text-secondary"> Forgot Password? </Link>
                    </p>

                    <div>
                        <button onClick={handleClickSignUp} type="submit" class="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 bg-secondary">Continue</button>
                    </div>
                    <p class="mt-10 text-center text-sm text-gray-500">
                        Don't have an account?
                        <Link to="/signup" class="font-semibold leading-6 text-secondary"> Sign up here</Link>
                    </p>

                    <div class="divider-wrapper"><span class="divider">Or</span></div>
                    <div onClick={handleClickGoogleAuth} className='ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 cursor-pointer flex justify-center items-center p-[6px] mt-2 bg-white rounded-lg'>
                        <img src={google} />
                        <p className='ml-2 font-semibold'>Continue with Google</p>
                    </div>
                </form>


            </div>
        </div>

    )
}

export default SignIn